import "./Platform.css";

import React, { Fragment, useEffect, useState } from "react";
import { domainPlatforms, timestampToDate } from "../../utils/utils";

export default function Platform({platform, licenseDomains, sendDomain, removePlatform, details}) {
    const [domains, setDomains] = useState(licenseDomains || [{id: 0, text: '', timelock: undefined}]);
    const [counter, setCounter] = useState(licenseDomains?.length || 1);
    const [impressionLimit, setImpressionLimit] = useState(details.ImpressionLimit);
    const [displaySubdomains] = useState(domainPlatforms.includes(platform));

    const updateDomain = (newDomain, id) => {
        const updatedDomains = domains.map(domain => {
            if (domain.id === id)
                domain.text = newDomain;

            return domain;
        });
        setDomains(updatedDomains);
    }

    const updateTimelock = (timelock, id) => {
        const updatedDomains = domains.map(domain => {
            if (domain.id === id) {
                if (timelock === "") {
                    domain.timelock = Date.now();
                } else {
                    domain.timelock = new Date(timelock).getTime();
                }
            }

            return domain;
        });

        setDomains(updatedDomains);
    }
    
    const newDomain = () => {
        setDomains([
            ...domains,
            {id: counter, text: '', timelock: undefined}
        ]);
        const newCount = counter + 1;
        setCounter(newCount);
    }
    
    const removeDomain = (id) => {
        const updatedDomains = domains.filter((domain) => 
            domain.id !== id
        );
        
        setDomains(updatedDomains);
        sendDomain(platform, updatedDomains);

        if (updatedDomains.length === 0) {
            removePlatform(platform);
        }
    }

    const switchWatermark = (id) => {
        const updatedDomains = domains.map(domain => {
            if (domain.id === id) {
                domain.watermark = !domain.watermark;
            }
            
            return domain;
        });

        setDomains(updatedDomains);
    }

    const switchTimelock = (id) => {
        const updatedDomains = domains.map(domain => {
            if (domain.id === id) {
                if (domain.timelock === undefined)
                    domain.timelock = new Date().setSeconds(0,0);
                else
                    domain.timelock = undefined;
            }
            
            return domain;
        });
        setDomains(updatedDomains);
    }

    const switchSubdomain = (id) => {
        const updatedDomains = domains.map(domain => {
            if (domain.id === id) {
                if (domain.subdomains === undefined)
                    domain.subdomains = !domain.subdomains;
                else
                    domain.subdomains = undefined;
            }

            return domain;
        });

        setDomains(updatedDomains);
    }

    const updateLimit = (newLimit, field) => {
        if (!isNaN(newLimit)) {
            details[field] = newLimit;

            // Change it if there're more than "impresisionlimit" fields
            details["Impressions"] = 0;
            setImpressionLimit(newLimit);
        } else {
            setImpressionLimit(0);

        }
    }

    useEffect(() => {
        // Set default license domains if they exists (used when loading an existing license)
        if (licenseDomains) {
            setDomains(licenseDomains);
            setCounter(licenseDomains.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div className="platform-container">
            <div className="platform">
                <label className={platform}>{platform}</label>
                <button onClick={newDomain}>Add</button>
                <div className="impression-limit-div">
                    Impression limit
                    <input className="impression-limit" type="text" placeholder="Impression limit" value={impressionLimit}
                            onChange={e => updateLimit(e.target.value, "ImpressionLimit")}/>
                </div>
                {
                    details.ImpressionLimit && details.Impressions &&
                    <div>{`${details.Impressions} Impression(s)`}</div>
                }
            </div>
            {
                domains?.map(domain => {
                    return (
                        <Fragment key={platform + domain.id}>
                            <div className="domain">
                                <div className="row">
                                    <input id={platform + domain.id} type="text" placeholder="domain.com" value={domain.text} 
                                        onChange={e => updateDomain(e.target.value, domain.id)} onBlur={e => sendDomain(platform, domains)}/>
                                    <div className="platform-buttons">
                                        <button className={"watermark-button " + (domain.watermark === true ? "enabled" : "disabled")} 
                                            onClick={() => switchWatermark(domain.id)} data-tooltip-id="my-tooltip"
                                            data-tooltip-content="If enabled, watermark will be displayed on this app. Not needed if license's watermark is enabled.">Watermark</button>
                                        <button className={"timelock-button " + (domain.timelock !== undefined ? "enabled" : "disabled")} 
                                            onClick={() => switchTimelock(domain.id)} data-tooltip-id="my-tooltip"
                                            data-tooltip-content="If enabled, 
                                            license will work on this app until license's timelock expires or the specific timelock expires, whatever comes first.">Timelock</button>
                                        {
                                            displaySubdomains &&
                                            <button className={"subdomains-button " + (domain.subdomains !== undefined ? "enabled" : "disabled")} 
                                                onClick={() => switchSubdomain(domain.id)} data-tooltip-id="my-tooltip"
                                                data-tooltip-content="If enabled, the subdomains of first level will be allowed for this domain.">Subdomains</button>
                                        }
                                        <button onClick={() => removeDomain(domain.id)}>Delete</button>
                                    </div>
                                </div>
                                {
                                domain.timelock && domain.timelock !== undefined &&
                                    <div className="row">
                                        <input className="timelock" type="datetime-local" name='timelock' defaultValue={timestampToDate(domain.timelock)}
                                            onBlur={e => updateTimelock(e.target.value, domain.id)}/>
                                        {
                                            domain.timelock < Date.now() &&
                                            <label className="timelock-expired" style={{color: "red"}}>
                                                Timelock is expired.
                                            </label>
                                        }
                                    </div>
                                }

                                <label id={platform + domain.id + "-error"} className="asterisk-error"></label>
                            </div>
                        </Fragment>
                    )
                })
            }
        </div>
    );
}