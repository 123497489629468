import React from "react";
import { useLocation, useNavigate } from "react-router-dom";


import './Header.css';
import Logout from "../Logout/Logout";

import logoIcon from '../../icons/logo.png';
import { CREDENTIALS, getVersion } from "../../utils/utils";

export default function Header({ setAuthenticated, credentials }) {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div id="header">
            <div id="menu">
                <img id="logo" src={logoIcon} alt="" onClick={e => navigate("/")}></img>
                {
                    credentials.access === CREDENTIALS.Admin && location.pathname !== "/testlicense" &&
                    <button id="test-license-button" onClick={() => navigate("/testlicense")}>Test license</button>
                }
            </div>
            {
                process.env.REACT_APP_API_STAGE === 'development' ?
                    <span id="dev">
                        DEVELOPMENT MODE
                    </span>
                    :
                    null
            }
            <div id="logout-div">
                <Logout setAuthenticated={setAuthenticated}></Logout>
                <div id="version" onClick={e => { navigate("/changelog") }}>{getVersion()}</div>
            </div>
        </div>
    );
}