import "./AddLicense.css";

import React, { Fragment, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import { availablePlatforms as availablePlatformsUtils, checkUser, editorPlatforms, getBaseURL, timestampToDate, webPlatforms } from "../../utils/utils";
import { Tooltip } from "react-tooltip";
import Customer from "../Basic/Customer";
import LocalPlatforms from "../Platform/LocalPlatforms";

export default function AddDemoLicense({setAuthenticated, setForceDashboardUpdate, credentials}) {
    const [license, setLicense] = useState({
        Customer: '',
        Timelock: timestampToDate(new Date()),
        ProductionLicense: false,
        Watermark: true,
        IsActive: true,
        Type: 'Demo',
        ResetRate: 1,
        LocalPlatforms: []
    });

    const [platforms, setPlatform] = useState([]);
    const [availablePlatforms, setAvailablePlatforms] = useState(availablePlatformsUtils);
    const [selectedPlatform, setSelectedPlatform] = useState(availablePlatforms[0]);
    const [generatingLicense, setGeneratingLicense] = useState(false);
    const [settingPlatform, setSettingPlatform] = useState(false);

    const navigate = useNavigate();

    const displayMandatoryValue = (name) => {
        if (name === "platform-selector") {
            const element = document.getElementById(name);
            if (settingPlatform)
                element.getElementsByTagName("select")[0].style.border = "2px solid red";
            else
                element.getElementsByTagName("button")[0].style.border = "2px solid red";
        } else {
            const element = document.getElementById(name);
            element.style.border = "2px solid red";
            
        }       
    }

    const displayErrorMessage = (message) => {
        const element = document.getElementById("error-message");
        element.innerHTML = "License could not be created.<br>Reason:<br>" + message;
    }

    const checkField = (name, value) => {
        const element = document.getElementById(name);

        switch (name) {
            case "customer-input":
                if (value === "") element.style.border = "2px solid red";
                else element.style.border = "";
                break;

            case "platform-selector":
                if (value === true) element.getElementsByTagName("select")[0].style.border = "";
                break;
            default:
                break;
        }
    }

    const generateLicense = async (license) => {        
        const newLicense = {
            ...license
        };

        newLicense.Timelock = new Date(license.Timelock).getTime();
    
        console.log('License', newLicense);

        try {
            setGeneratingLicense(true)
            
            if (!await checkUser(setAuthenticated))
                return;

            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
    
            const key = await fetch(getBaseURL() + '/addlicense', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                },
                body: JSON.stringify({
                    Details: newLicense
                })
            });
            
            const resp = await key.json();

            if (key.status === 200 && !resp.errorMessage) {
                setGeneratingLicense(false);
                setForceDashboardUpdate(true);
    
                navigate(`/license/${resp.key}`);
            } else {
                setGeneratingLicense(false);
                console.error('Error generating license', resp.errorMessage);
                displayErrorMessage(resp.errorMessage + "<br>Please try again and, if the problem persists, contact with support.");
            }
        } catch (e) {
            console.error('License error', e);
            displayErrorMessage("Unknown error:<br>" + e + "<br>Please contact with support if the problem persists.");
        }
    }

    const createLicense = async (license) => {
        let message = "";

        if (!license.Customer || license.Customer === "") {
            displayMandatoryValue("customer-input");
            message += "Customer field cannot be empty.<br>";
        }

        if (!license.Timelock) {
            displayMandatoryValue("timelock-input");
            message += "Timelock must be set.<br>";
        }

        if (platforms.length === 0) {
            displayMandatoryValue("platform-selector");
            message += "At least one platform must be selected<br>";
        }

        if (!license.Customer || license.Customer === "" || !license.Timelock || platforms.length === 0) {
            displayErrorMessage(message);
            return;
        }

        generateLicense(license);
    }

    const checkTimelock = (event) => {
        if (new Date(event.target.value).getTime() >= new Date()) {
            setLicense({...license, Timelock: event.target.value});
        } else {
            const newCurrentTime = timestampToDate(new Date());

            setLicense({...license, Timelock: newCurrentTime})
            event.target.value = newCurrentTime;
        }
    }

    const addPlatform = (event) => {
        const newPlatforms = [
            ...platforms,
            selectedPlatform
        ];

        const localPlatforms = [...license.LocalPlatforms];

        if (!localPlatforms.includes(selectedPlatform))
            localPlatforms.push(selectedPlatform);

        if (webPlatforms.includes(selectedPlatform)) {
            if (!localPlatforms.includes("html5"))
                localPlatforms.push("html5");
        }

        if (editorPlatforms.includes(selectedPlatform)) {
            if (!localPlatforms.includes("windows"))
                localPlatforms.push("windows");       

            if (!localPlatforms.includes("macos"))
                localPlatforms.push("macos");            
        }

        setPlatform(newPlatforms);

        setLicense({
            ...license,
            Platforms: newPlatforms,
            LocalPlatforms: localPlatforms
        });

        const newAvailablePlatforms = availablePlatforms.filter(platform => {
            if (platform === selectedPlatform)
                return false;

            return true;
        });

        setAvailablePlatforms(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);
        setSettingPlatform(false);
    }

    const removePlatform = (removedPlatform) => {
        const newPlatforms = platforms.filter(platform => {
            if (platform === removedPlatform)
                return false;

            return true;
        });
        
        setPlatform(newPlatforms);
        setLicense({
            ...license,
            Platforms: newPlatforms
        });

        const newAvailablePlatforms = availablePlatformsUtils.filter(
            platform => availablePlatforms.includes(platform) || platform === removedPlatform);    

        setAvailablePlatforms(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);
    }

    const updateLimit = (newLimit, field) => {
        if (newLimit === "")
            setLicense({...license, [field]: newLimit});
        else if (!isNaN(newLimit) && Number(newLimit) >= 0)
            setLicense({...license, [field]: Number(newLimit)});
    }
    
    return(
        <div id="new-license">
            <div id="title">Create new demo license</div>
            
            <Customer checkField={checkField} name={license.Customer} setLicense={(value) => {setLicense({...license, Customer: value})}}/>

            <div id="timelock" className="input-container">
                <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Date until which the license will work">Timelock</label>
                <input id="timelock-input" type="datetime-local" name='timelock' min={license.Timelock} defaultValue={license.Timelock} onBlur={checkTimelock}></input>
            </div>

            <div id="duration-limit" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Allowed duration playback for demo apps">Duration limit</label>
                    <input className="number-input" name='duration' placeholder="No limit" value={license.DurationLimit} onChange={e => updateLimit(e.target.value, "DurationLimit")}></input>
                </div>

                <div id="impression-limit" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Limit of impressions for demo apps">Impression limit</label>
                    <input className="number-input" name='impression-limit' placeholder="No limit" value={license.ImpressionLimit} onChange={e => updateLimit(e.target.value, "ImpressionLimit")}></input>
                </div>

            <div id="local-platforms-container" className="input-container">
                <label data-tooltip-id="my-tooltip"
            data-tooltip-content="Platforms where the playback will be enabled using local domains/apps (editors included)">Local Platforms</label>
                <LocalPlatforms platforms={license.LocalPlatforms} updatePlatforms={(localPlatforms) => setLicense({...license, LocalPlatforms: localPlatforms})}/>
            </div>

            <div id="apps-container">
                <label className="apps-title" data-tooltip-id="my-tooltip"
                data-tooltip-content="Enabled platforms"
                >Platforms</label>

                {
                platforms.length > 0 ? (
                <div id="platforms">
                    {
                        platforms.map(platform => {
                            return (
                                <div key={platform} className="row">
                                    <div className={"platform " + platform} key={platform}>
                                        {platform}
                                    </div>
                                    <button onClick={() => removePlatform(platform)}>Delete</button>
                                </div>
                            )
                        })
                    }
                </div>) : (null)
                }

                {
                availablePlatforms.length > 0 ?
                <div id="platform-selector">
                    {
                        settingPlatform ?
                            <Fragment>
                                <select onChange={e => setSelectedPlatform(e.target.value)}>
                                    {
                                        availablePlatforms.map(platform => {
                                            return (
                                                <Fragment key={platform}>
                                                    <option value={platform}>{platform}</option>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </select>
                                <button onClick={e => {checkField("apps-container", true); addPlatform();}}>Add</button>
                                <button onClick={() => setSettingPlatform(false)}>Cancel</button>
                            </Fragment>
                        :
                            <button onClick={() => setSettingPlatform(true)}>Add platform</button>
                    }
                    </div>
                : (null)
            }
            </div>
                
            <div id="watermark" className="checkbox-container">
                <input type="checkbox" onChange={e => setLicense({ ...license, Watermark: e.target.checked })} checked={license.Watermark} />
                <div data-tooltip-id="my-tooltip"
                data-tooltip-content="If enabled, watermark will be displayed on all the apps">Watermark</div>
            </div>

            <div id="create-license">
                <button className="create-license-button" onClick={(e) => createLicense(license, navigate)}>{generatingLicense ? 'Generating license...' : 'Create license'}</button>
                <div id="error-message"></div>
            </div>

            <Tooltip id="my-tooltip" className="my-tooltip" multiline={true} />
        </div>
    );
}