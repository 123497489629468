import "./LocalPlatforms.css";

import React, { Fragment, useEffect, useRef, useState } from "react";
import { availablePlatforms } from "../../utils/utils";

export default function LocalPlatforms({platforms, updatePlatforms}) {
    const [platformList, setPlatformList] = useState(platforms ? 
        availablePlatforms.filter(platform => !platforms.includes(platform)) : 
        availablePlatforms);
    const [filteredPlatforms, setFilteredPlatforms] = useState(platformList);
    const [selectedPlatforms, setSelectedPlatforms] = useState(platforms || []);
    const [selectedPlatform, setSelectedPlatform] = useState(platformList[0]);

    useEffect(() => {
        setPlatformList(platforms ? 
            availablePlatforms.filter(platform => !platforms.includes(platform)) : 
            availablePlatforms);
        setFilteredPlatforms(platformList);
        setSelectedPlatform(platformList[0]);
        setSelectedPlatforms(platforms || []);
    }, [platforms]);

    const updateText = (event) => {
        const newPlatformList = platformList.filter((platform) => platform.includes(event.target.value));
        setFilteredPlatforms(newPlatformList);
        setSelectedPlatform(newPlatformList[0]);
    };

    const selectPlatform = (e) => {
        let newPlatform;

        if (e && e.key === "Enter" && selectedPlatform) {
            newPlatform = selectedPlatform;
        } else if (typeof e === "string") {
            newPlatform = e;
        } else {
            return;
        }

        selectedPlatforms.push(newPlatform);
        updatePlatforms(selectedPlatforms);
        setSelectedPlatforms(selectedPlatforms);
        
        const newAvailablePlatforms = platformList.filter(platform => platform !== newPlatform);
        setPlatformList(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);
        
        document.getElementById("local-input").value = "";
        
        setFilteredPlatforms(newAvailablePlatforms);
    }

    const removePlatform = (platform) => {
        const newSelectedPlatforms = selectedPlatforms.filter(selPlatform => selPlatform !== platform);
        updatePlatforms(newSelectedPlatforms);
        setSelectedPlatforms(newSelectedPlatforms);
        
        platformList.push(platform);
        setPlatformList(platformList);

        document.getElementById("local-input").value = "";

        setFilteredPlatforms(platformList);
    }

    return (
        <div id="local-platforms">
            <div id="selected-platforms">
            {
                selectedPlatforms.map(platform => {
                    return (
                        <div key={platform} className={"platform " + platform}>
                            <div className="platform-name">{platform}</div>
                            <div className="delete-local-platform" onClick={() => removePlatform(platform)}>x</div>
                        </div>
                    )
                })
            }
            </div>

            <div id="selector-div">
                <input id="local-input" type="text" autoComplete="off" onChange={updateText} onKeyUp={selectPlatform}/>

                <div id="platform-list" style={filteredPlatforms.length > 0 ? {} : {visibility: "hidden"}}>
                {
                    filteredPlatforms.map(platform => {
                        return (
                            <div key={platform} className="filtered-platform" onClick={() => selectPlatform(platform)}>
                                    {platform}
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    );
}