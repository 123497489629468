import "../UpdateLicense.css";

import { Fragment, useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Auth } from "aws-amplify";

import { checkUser, CREDENTIALS, getBaseURL } from "../../../utils/utils";
import DemoApp from "./DemoApp";
import ProductionApp from "./ProductionApp";

import Back from "../../../icons/back.png"

export default function DisplayApps({ setAuthenticated, setForceDashboardUpdate, credentials }) {
    const [licenseDetails, setLicenseDetails] = useState(useLocation().state?.licenseDetails || false);
    const type = useParams()['type'];
    const platform = useParams()['platform'];
    const [appDoms, setAppDoms] = useState(useLocation().state?.appDomains || false);
    const [otherApps, setOtherApps] = useState(useLocation().state?.otherApps || false);
    const navigate = useNavigate();
    const key = useParams()['key'];

    // TODO: Use to delete apps
    const [originalAppDomains, setOriginalAppDomains] = useState();

    const [updating, setUpdating] = useState(false);

    const displayErrorMessage = (message) => {
        const element = document.getElementById("error-message");
        element.innerHTML = "Apps could not be updated.<br>If the problem persists, please contact with backend team.";
    }

    const getLicenseDetails = async (key) => {
        try {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            const resp = await fetch(getBaseURL() + '/getlicense/' + key, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                }
            });

            if (resp.status === 200) {
                const licenseDetails = (await resp.json()).data;

                const newApps = [];
                const otherApps = [];

                for (const SK of licenseDetails) {
                    if (SK['SK'] === "Details") {
                        setLicenseDetails(SK);
                    } else {
                        const elementType = SK["ProductionLicense"] ? "prod" : "demo";
                        const elementPlatform = SK["SK"].split('_', 1)[0];

                        if (platform === elementPlatform) {
                            if (elementType === type)
                                newApps.push(SK);
                            else
                                otherApps.push(SK);
                        }
                    }
                }

                setAppDoms(newApps);
                setOtherApps(newApps);
            } else {
                displayErrorMessage(resp.errorMessage + "<br>Please try again and, if the problem persists, contact with support.");
                console.error('Get licenseDetails error.', resp.status === 404 ? 'License not found' : 'Server error');
            }
        } catch (error) {
            displayErrorMessage("Unknown error:<br>" + error + "<br>Please contact with support if the problem persists.");
            console.error("Key could't be found", error);
        }
    }

    const clearErrorMessage = () => {
        document.getElementById("error-message").innerHTML = "";
    }

    const updateLicense = async (apps, resetCounter) => {
        clearErrorMessage();

        try {
            setUpdating(true);

            if (!await checkUser(setAuthenticated))
                return;

            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            const resp = await fetch(getBaseURL() + '/updatelicense', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                },
                body: JSON.stringify({
                    Details: licenseDetails,
                    AppDomains: apps
                    // DeletedAppDomains: deletedAppDomains
                })
            });

            setUpdating(false);

            if (resp.status === 200) {
                navigate(`/license/${key}`, { state: { licenseDetails: licenseDetails } });
                setForceDashboardUpdate(true);
                console.log('Key updated', licenseDetails);
            } else {
                console.log('Apps could not be updated. Status error:', resp.status);
                displayErrorMessage("Key couldn't be updated. Please try again and, if the problem persists, contact with backend team.");
            }
        } catch (error) {
            setUpdating(false);
            console.error("Key could't be updated");
            displayErrorMessage("Couldn't connect to the server. Please try again and, if the problem persist, conctact with backend team.");
        }
    };

    // TODO: Implement delete app
    const deleteApp = (appDomain) => {

    };

    const switchType = () => {
        navigate(`/license/${key}/${type === "demo" ? "prod" : "demo"}`, {
            state: {
                licenseDetails: licenseDetails,
                type: type === "demo" ? "Production" : "Demo",
                appDomains: otherApps,
                platform: platform,
                otherApps: appDoms
            }
        });
    }

    useEffect(() => {
        getLicenseDetails(key);

        setInterval(() => getLicenseDetails(key), 60000);
    }, []);

    if (appDoms) {
        return (
            <div className={"update-app" + (credentials.access >= CREDENTIALS.FullAccess ? "" : " no-update")}>
                <div id="head-container">
                    <img id="back-button" src={Back} onClick={() => navigate(`/license/${key}`, { state: { licenseDetails: licenseDetails } })} />
                    <div id="title">LICENSE {key}</div>
                </div>

                <div id="platform-container">
                    <label className={"platform " + platform}>{platform}</label>
                    <label>{type === 'demo' ? 'Demo' : 'Production'} Apps</label>
                </div>
                {
                    appDoms.length > 0 ?
                        <Fragment>
                            <table id="apps-container">
                                {
                                    type === "Production" &&
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th>Tracking</th>
                                            <th>Total Impressions</th>
                                            <th>Daily Impressions</th>
                                            <th>Impression limit</th>
                                            <th>First Impression</th>
                                            <th>Last Impression</th>
                                            <th>Timelock</th>
                                            <th>Duration limit</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                }
                                {
                                    type === "demo" &&
                                    <thead>
                                        <tr>
                                            <th>App</th>
                                            <th>Total Impressions</th>
                                            <th>Daily Impressions</th>
                                            <th>First Impression</th>
                                            <th>Last Impression</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                }
                                {
                                    appDoms.map((appDomain, idx) => {
                                        return (
                                            <tbody key={appDomain.SK}>
                                                {
                                                    type === "demo" ?
                                                        <DemoApp appDomain={appDomain} impressionLimit={licenseDetails.ImpressionLimit}></DemoApp>
                                                        :
                                                        <ProductionApp appDomain={appDomain}></ProductionApp>
                                                }
                                                {/* <button className={"delete-button"} 
                                                onClick={() => deleteApp(appDomain)}>Watermark</button> */}
                                            </tbody>
                                        )
                                    })
                                }
                            </table>
                        </Fragment>
                        :
                        <div>
                            <h3>No Apps found for this platform</h3>
                        </div>
                }

                <div id="additional-buttons">
                    {
                        appDoms.length > 0 &&
                        credentials.access >= CREDENTIALS.FullAccess &&
                        <button className="update-licenseDetails" onClick={(e) => updateLicense(appDoms)}>{updating ? 'Updating...' : 'Update'}</button>
                    }
                    {
                        otherApps && otherApps.length > 0 &&
                        <button className="switch-apps" onClick={switchType}>{type === "demo" ? "Production" : "Demo"} Apps</button>
                    }
                    <div id="error-message"></div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <h3>Loading apps...</h3>
            </div>
        )
    }
}