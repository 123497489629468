import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import ChangelogMD from "./Changelog.md"

export default function Changelog() {
    const [text, setText] = useState();

    const retrieveData = async () => {
        const response = await fetch(ChangelogMD);
        const text = await response.text();
        setText(text);
    }
    
    useEffect(() => {
        retrieveData();
    }, []);

    return (
        <ReactMarkdown children={text}/>
    )
}