import "./UpdateLicense.css";

import { Fragment, useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Auth } from "aws-amplify";

import Platform from '../Platform/Platform';
import { appDomainToPlatform, availablePlatforms as availablePlatformsUtils, checkUser, CREDENTIALS, editorPlatforms, getBaseURL, platformAdapter, platformToAppDomain, retrieveDomains, timestampToDate, webPlatforms } from "../../utils/utils";
import LicenseDetails from "../LicenseDetails/LicenseDetails";
import { Tooltip } from "react-tooltip";
import Customer from "../Basic/Customer";
import LocalPlatforms from "../Platform/LocalPlatforms";
import LastImpression from "./LastImpression/LastImpression";

export default function ProductionLicense({ setAuthenticated, setForceDashboardUpdate, credentials, license, licenseAppDomains }) {
    const licenseState = license || false;
    const navigate = useNavigate();
    const key = useParams()['key'];

    const [licenseDetails, setLicenseDetails] = useState();
    const [appDomains, setAppDomains] = useState();
    const [originalAppDomains, setOriginalAppDomains] = useState();

    const [availablePlatforms, setAvailablePlatforms] = useState(availablePlatformsUtils);
    const platformsArray = licenseState ? platformAdapter(licenseState.Platforms) : {};
    const [platforms, setPlatforms] = useState(platformsArray);
    const [selectedPlatform, setSelectedPlatform] = useState(availablePlatforms[0]);

    const [settingPlatform, setSettingPlatform] = useState(false);

    const [oldTimelock, setOldTimelock] = useState();

    const [updating, setUpdating] = useState(false);
    const [loading, setLoading] = useState(false);

    const displayMandatoryValue = (name) => {
        if (name === "platform-selector") {
            const element = document.getElementById(name);
            element.getElementsByTagName("select")[0].style.border = "2px solid red";
        } else {
            const element = document.getElementById(name);
            element.style.border = "2px solid red";
            
        }       
    }

    const displayErrorMessage = (message) => {
        const element = document.getElementById("error-message");
        element.innerHTML = "License could not be updated.<br>Reason:<br>" + message;
    }

    const clearErrorMessage = () => {
        document.getElementById("error-message").innerHTML = "";
    }

    const checkField = (name, value) => {
        const element = document.getElementById(name);

        switch (name) {
            case "customer-input":
                if (value === "") element.style.border = "2px solid red";
                else element.style.border = "";
                break;

            case "platform-selector":
                if (value === true) element.getElementsByTagName("select")[0].style.border = "";
                break;
            default:
                break;
        }
    }

    const checkAsterisks = (production) => {
        for (const platform of Object.keys(platforms)) {
            appDomains[platform].forEach((domain) => {
                const element = document.getElementById(platform + domain.id);

                if (domain.text === "")
                    element.style.border = "2px solid red";
                else {
                    const errorMsg = document.getElementById(platform + domain.id + "-error");
                    
                    // Asterisk (*) only allowed on non-production
                    if (production && domain.text === "*") {
                        element.style.border = "2px solid red";
                        errorMsg.innerHTML = "Asterisk cannot be used on production licenses. This domain will be ignored."
                    } else {
                        element.style.border = "";
                        errorMsg.innerHTML = ""
                    }
                }
            });
        }
    }

    const getLicenseDetails = async (key) => {
        if (loading)
            return;

        setLoading(true);

        try {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            const resp = await fetch(getBaseURL() + '/getlicense/' + key, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                }
            });

            if (resp.status === 200) {
                const licenseDetails = (await resp.json()).data;
                const appDomains = {};

                for (const SK of licenseDetails) {
                    if (SK['SK'] === "Details") {
                        setLicenseDetails(SK);

                        const newAvailablePlatforms = availablePlatforms.filter(platform => {
                            return !(platform in SK.Platforms);
                        });
                        setAvailablePlatforms(newAvailablePlatforms);
                        setSelectedPlatform(newAvailablePlatforms[0]);
                        setPlatforms(platformAdapter(SK.Platforms));

                        const oldTime = timestampToDate(SK.Timelock);
                        setOldTimelock(oldTime);
                    } else {
                        const platform = SK["SK"].split('_', 1)[0];

                        if (platform in appDomains) {
                            appDomains[platform].push(SK);
                        } else {
                            appDomains[platform] = [SK];
                        }
                    }
                }

                for (const platform of Object.keys(appDomains)) {
                    appDomains[platform] = appDomainToPlatform(appDomains[platform]);
                }

                setAppDomains(appDomains);
                // Create a deep copy of appDomains so they're not modified when the original is manipulated
                const deepAppDomains = JSON.parse(JSON.stringify(appDomains));
                setOriginalAppDomains(deepAppDomains);
            } else {
                updateLicenseState(null);
                displayErrorMessage(resp.errorMessage + "<br>Please try again and, if the problem persists, contact with support.");
            }
        } catch (error) {
            updateLicenseState(null);
            displayErrorMessage("Unknown error:<br>" + error + "<br>Please contact with support if the problem persists.");
        } finally {
            setLoading(false);
        }
    }

    const updateLicense = async (licenseDetails, resetCounter) => {
        clearErrorMessage();

        let message = "";

        if (!licenseDetails.Customer || licenseDetails.Customer === "") {
            displayMandatoryValue("customer-input");
            message += "Customer field cannot be empty.<br>";
        }

        if (!licenseDetails.Timelock) {
            displayMandatoryValue("timelock-input");
            message += "Timelock must be set.<br>";
        }

        if (Object.keys(platforms).length === 0) {
            displayMandatoryValue("platform-selector");
            message += "At least one platform must be selected<br>";
        }

        if (!licenseDetails.Customer || licenseDetails.Customer === "" || !licenseDetails.Timelock || Object.keys(platforms).length === 0) {
            displayErrorMessage(message);
            return;
        }

        if (resetCounter) {
            licenseDetails.MonthlyCounter = 0;
        }

        const updatedLicense = licenseDetails;
        const formattedAppDomains = [];
        const deletedAppDomains = [];

        for (const platform of Object.keys(platforms)) {
            const deletedFromPlatform = [];

            // Remove modified or deleted appDomains
            if (platform in originalAppDomains) {
                for (const oAP of originalAppDomains[platform]) {
                    let found = false;

                    for (const appDomain of appDomains[platform]) {
                        if (appDomain.id === oAP.id) {
                            found = true;

                            if (appDomain.text !== oAP.text) {
                                deletedFromPlatform.push(oAP);
                                found = true;
                            }
                        }
                    }

                    if (!found)
                        deletedFromPlatform.push(oAP);
                }

                if (deletedFromPlatform.length > 0) {
                    const appDomain = platformToAppDomain(deletedFromPlatform, platform, key).map(appDom => {
                        return {
                                Key: appDom.Key,
                                SK: appDom.SK
                        }
                    });

                    deletedAppDomains.push(...appDomain);
                }
            }
            
            if (platform === "html5") {
                const platformDomains = platformToAppDomain(retrieveDomains(appDomains[platform]), platform, key);
                formattedAppDomains.push(...platformDomains);
            } else {
                formattedAppDomains.push(...platformToAppDomain(appDomains[platform], platform, key));
            }

            if (Object.keys(platforms[platform]).length === 0) {
                // Add value so no "undefined" is sent to the server
                platforms[platform]["Impressions"] = 0;
            }

            if (platforms[platform].Remove) 
                delete platforms[platform];
        }

        updatedLicense.Platforms = {...platforms};
        updatedLicense.Timelock = new Date(licenseDetails.Timelock).getTime();

        if (updatedLicense.Type === undefined)
            updatedLicense.Type = "Basic";

        try {
            setUpdating(true);

            if (!await checkUser(setAuthenticated))
                return;

            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            try {
                await fetch(getBaseURL() + '/updatelicense', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Lic-Mag': token
                    },
                    body: JSON.stringify({
                        Details: updatedLicense,
                        AppDomains: formattedAppDomains,
                        DeletedAppDomains: deletedAppDomains
                    })
                });

                setUpdating(false);
                navigate(".", { licenseDetails: updatedLicense });
                setForceDashboardUpdate(true);
                console.log('Key updated', updatedLicense);
    
                getLicenseDetails(key);
            } catch(error) {
                console.error("Fetching error", error);

                displayErrorMessage("Couldn't connect to the server. Please try again and, if the problem persist, conctact with backend team.");
            } finally {
                setUpdating(false);
            }           
        } catch (error) {
            console.error("Key could't be updated");
        }
    }

    const updateLicenseState = (licenseDetails) => {
        if (licenseDetails === null) {
            setLicenseDetails(null);
            return false;
        }

        // TODO: Should never pass here (is always set to null), so it should be removed
        setPlatforms(licenseDetails.Platforms);

        const newAvailablePlatforms = availablePlatforms.filter(platform => {
            for (const oldPlatform of licenseDetails.Platforms) {
                if (platform === oldPlatform)
                    return false;
            }

            return true;
        })
        setAvailablePlatforms(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);

        let oldDomains = {};
        for (const platform in licenseDetails.Platforms) {
            const domainsOnPlatform = [];

            oldDomains[platform] = domainsOnPlatform;
        }

        setLicenseDetails(licenseDetails);
    }

    const updateDomains = useCallback((platform, domains) => {
        domains.forEach(domain => {
            const element = document.getElementById(platform + domain.id);

            if (domain.text === "")
                element.style.border = "2px solid red";
            else if (licenseDetails.ProductionLicense && domain.text === "*") { // Asterisk (*) only allowed on non-production
                element.style.border = "2px solid red";
                const errorMsg = document.getElementById(platform + domain.id + "-error");
                errorMsg.innerHTML = "Asterisk cannot be used on production licenses. This domain will be ignored."
            } else {
                element.style.border = "";
                const errorMsg = document.getElementById(platform + domain.id + "-error");
                errorMsg.innerHTML = ""
            }            
        });
        
        appDomains[platform] = domains;
        setAppDomains(appDomains);
    }, [appDomains, licenseDetails]);

    const checkTimelock = (event) => {
        const eventDate = new Date(event.target.value).getTime();

        if (eventDate === NaN) {
            const newCurrentTime = timestampToDate(new Date());

            setLicenseDetails({ ...licenseDetails, Timelock: newCurrentTime });
            event.target.value = newCurrentTime;
        } else {
            setLicenseDetails({ ...licenseDetails, Timelock: eventDate });
        }
    }

    const addPlatform = (event) => {
        platforms[selectedPlatform] = {};

        if (licenseDetails.LocalPlatforms === undefined) {
            if (licenseState.Platforms instanceof Array)
                licenseDetails.LocalPlatforms = [...licenseState.Platforms];
            else
                licenseDetails.LocalPlatforms = [...Object.keys(licenseState.Platforms)];
        }

        const localPlatforms = [...licenseDetails.LocalPlatforms];

        if (!localPlatforms.includes(selectedPlatform))
            localPlatforms.push(selectedPlatform);

        if (webPlatforms.includes(selectedPlatform)) {
            if (!localPlatforms.includes("html5"))
                localPlatforms.push("html5");
        }

        if (editorPlatforms.includes(selectedPlatform)) {
            if (!localPlatforms.includes("windows"))
                localPlatforms.push("windows");       

            if (!localPlatforms.includes("macos"))
                localPlatforms.push("macos");            
        }

        setPlatforms(platforms);
        setLicenseDetails({
            ...licenseDetails,
            Platforms: platforms,
            LocalPlatforms: localPlatforms
        });

        const newAvailablePlatforms = availablePlatforms.filter(platform => {
            if (platform === selectedPlatform)
                return false;

            return true;
        });

        setAvailablePlatforms(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);
        setSettingPlatform(false);
    }

    const removePlatform = (removedPlatform) => {
        platforms[removedPlatform]["Remove"] = true;
        setPlatforms(platforms);

        setLicenseDetails({
            ...licenseDetails,
            Platforms: platforms
        });

        const newAvailablePlatforms = availablePlatformsUtils.filter(
            platform => availablePlatforms.includes(platform) || platform === removedPlatform);

        setSelectedPlatform(newAvailablePlatforms[0]);
        setAvailablePlatforms(newAvailablePlatforms);
    }

    const handleDownload = () => {
        return;
    };
    
    // License comes from UpdateLicese, if no appDomains are sent, retrieve them
    useEffect(() => {
        if (licenseState && licenseState.Key === key) { // Key should always be correct, we check just in case
            console.log('Setting licenseDetails...', licenseState);

            const newAvailablePlatforms = availablePlatforms.filter(platform => {
                return !(platform in platforms);
            });

            setAvailablePlatforms(newAvailablePlatforms);
            setSelectedPlatform(newAvailablePlatforms[0]);

            const oldDomains = {};

            if (licenseAppDomains) {
                for (const SK of licenseAppDomains) {
                    const platform = SK["SK"].split('_', 1)[0];
                    
                    if (platform in oldDomains) {
                        oldDomains[platform].push(SK);
                    } else {
                        oldDomains[platform] = [SK];
                    }
                }

                for (const platform of Object.keys(oldDomains)) {
                    oldDomains[platform] = appDomainToPlatform(oldDomains[platform]);
                }

                setAppDomains(oldDomains);
                // Create a deep copy of appDomains so they're not modified when the originals are manipulated
                const deepAppDomains = JSON.parse(JSON.stringify(oldDomains));
                setOriginalAppDomains(deepAppDomains);
            } else {
                getLicenseDetails(key);
            }

            setLicenseDetails(licenseState);

            const oldTime = timestampToDate(licenseState.Timelock);
            setOldTimelock(oldTime);
        } else {
            getLicenseDetails(key);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (licenseDetails) {
        return (
            <Fragment>
                <div className="flex center">
                    <div id="title">LICENSE {key}</div>

                    <div>
                        {/* Download CSV */}
                        <div id="download-sheet" className="disabled" data-tooltip-id="my-tooltip"
                            data-tooltip-content="Download XLSX is not availabe for this license" onClick={handleDownload}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
                            </svg>
                        </div>
                    </div>
                </div>


                <Customer checkField={checkField} name={licenseDetails.Customer} setLicense={(value) => {setLicenseDetails({...licenseDetails, Customer: value})}}/>

                <div id="timelock" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Date until which the license will work">Timelock</label>
                    <input id="timelock-input" type="datetime-local" name='timelock' min={oldTimelock} defaultValue={oldTimelock} onBlur={checkTimelock}></input>
                </div>

                <div id="local-platforms-container" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Platforms where the playback will be enabled using local domains/apps (editors included)">Local Platforms</label>
                    <LocalPlatforms platforms={licenseDetails.LocalPlatforms} updatePlatforms={(localPlatforms) => setLicenseDetails({...licenseDetails, LocalPlatforms: localPlatforms})}/>
                </div>

                <div id="apps-container">
                    <div className="apps-title" data-tooltip-id="my-tooltip"
                    data-tooltip-content="Domains are used on HTML5, PS4, and PS5. Any other platforms use the appId of the app"
                    >Apps</div>
                    {
                        loading ?
                            <h4>Loading Apps</h4>
                        :
                            appDomains ?
                                Object.keys(platforms).map(platform => {
                                    return !platforms[platform].Remove &&
                                    <Platform platform={platform} key={platform} details={platforms[platform]}
                                        updateDomains={updateDomains} licenseDomains={appDomains[platform]} sendDomain={updateDomains} removePlatform={removePlatform} />
                                })
                            :
                            <h4>No Apps found. This may be an error, please contact with backend team.</h4> // This message should never appear
                    }
                    {
                        availablePlatforms.length > 0 ?
                        <div id="platform-selector">
                            {
                                settingPlatform ?
                                    <Fragment>
                                        <select onChange={e => setSelectedPlatform(e.target.value)}>
                                            {
                                                availablePlatforms.map(platform => {
                                                    return (
                                                        <Fragment key={platform}>
                                                            <option value={platform}>{platform}</option>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </select>
                                        <button onClick={e => {checkField("apps-container", true); addPlatform();}}>Add</button>
                                        <button onClick={() => setSettingPlatform(false)}>Cancel</button>
                                    </Fragment>
                                :
                                    <button onClick={() => setSettingPlatform(true)}>Add platform</button>
                            }
                            </div>
                        : (null)
                    }
                </div>

                {
                    credentials.access >= CREDENTIALS.FullAccess && 
                    (licenseDetails.Impressions !== undefined || licenseDetails.LocalImpressions !== undefined) &&
                    <div id="impression-container">
                        <div id="impressions">
                            <div>{licenseDetails.Impressions} Impression{licenseDetails.Impressions > 1 ? "s" : ""}</div>
                            <button className="button" onClick={(e) => updateLicense(licenseDetails, true)}>Reset impressions</button>
                        </div>
                        {
                            licenseDetails.LocalImpressions !== undefined &&
                            <div id="local-impressions">{licenseDetails.LocalImpressions} Local Impression{licenseDetails.LocalImpressions > 1 ? "s" : ""}</div>
                        }
                        {
                            licenseDetails.LastImpression &&
                            <div id="last-impression">
                                <div id="last-impression-title">Last Impression</div>
                                <LastImpression lastImpression={licenseDetails.LastImpression} />
                            </div>
                        }
                    </div>
                }

                {
                    !credentials.type &&
                    <div id="webgl" className="checkbox-container">
                        <input type="checkbox" onChange={e => setLicenseDetails({...licenseDetails, Type: e.target.checked ? 'WebGL' : 'Basic'})} checked={licenseDetails.Type === 'WebGL' ? true : false}/>
                        WebGL
                    </div>
                }

                <div id="production" className="checkbox-container">
                    <input type="checkbox" onChange={e =>{checkAsterisks(e.target.checked); setLicenseDetails({ ...licenseDetails, ProductionLicense: e.target.checked })}} checked={licenseDetails.ProductionLicense} />
                    <div data-tooltip-id="my-tooltip"
                    data-tooltip-content="Indicates the license is being used on a production app. Asterisk (*) canot be used on production licenses.">Production License</div>
                </div>

                <div id="watermark" className="checkbox-container">
                    <input type="checkbox" onChange={e => setLicenseDetails({ ...licenseDetails, Watermark: e.target.checked })} checked={licenseDetails.Watermark} />
                    <div data-tooltip-id="my-tooltip"
                    data-tooltip-content="If enabled, watermark will be displayed on all the apps">Watermark</div>
                </div>

                <div id="is-active" className="checkbox-container">
                    <input type="checkbox" onChange={e => setLicenseDetails({ ...licenseDetails, IsActive: e.target.checked })} checked={licenseDetails.IsActive} />
                    <div data-tooltip-id="my-tooltip"
                    data-tooltip-content="Disabling the license will prevent its usage on all platforms, local domains included.">Active</div>
                </div>

                <div id="tracking" className="checkbox-container">
                    <input type="checkbox" onChange={e => setLicenseDetails({ ...licenseDetails, Tracking: e.target.checked })} checked={licenseDetails.Tracking} />
                    Tracking
                </div>

                <LicenseDetails licenseDetails={licenseDetails}></LicenseDetails>
                
                {
                    credentials.access >= CREDENTIALS.FullAccess &&
                    <div id="create-license">
                        <button id="update-button" onClick={(e) => !updating && updateLicense(licenseDetails)}>{updating ? 'Updating...' : 'Update'}</button>
                        <div id="error-message"></div>
                    </div>
                }
                <Tooltip id="my-tooltip" className="my-tooltip" multiline={true} />
            </Fragment>
        );
    } else if (licenseDetails === undefined) {
        return (
            <div>
                <h3>Loading license details...</h3>
            </div>
        )
    } else {
        return (
            <div>
                <h3>License not found</h3>
            </div>
        )
    }
}