import "./Basic.css";

import React from "react";

export default function Customer({ checkField, name, setLicense }) {
    return <div id="customer" className="input-container" >
        <label data-tooltip-id="my-tooltip"
        data-tooltip-content="Provide the customer's name or the usage of the license">Customer</label>
        <input id="customer-input" name="customer" placeholder="Customer name" value={name} 
        onChange={e => {checkField("customer-input", e.target.value); setLicense(e.target.value)}}></input>
    </div>
}