import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useState } from 'react';

import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login';
import AddLicense from './components/AddLicense/AddLicense';
import UpdateLicense from './components/UpdateLicense/UpdateLicense';
import TestLicense from './components/TestLicense/TestLicense';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Header from './components/Header/Header';
import { CREDENTIALS } from './utils/utils';
import DisplayApps from './components/UpdateLicense/Apps/DisplayApps';
import Changelog from './components/Changelog/Changelog';

Amplify.configure(awsconfig);

async function autoLogIn(setAuthenticated, setCredentials) {
  try {
    const session = await Auth.currentSession();

    if (session) {
      const groups = session.accessToken.payload['cognito:groups'];
      let credentials = {};

      if (groups.includes("Admin")) {
        credentials.access = CREDENTIALS.Admin;
      } else if (groups.includes("FullAccess")) {
        credentials.access = CREDENTIALS.FullAccess;
      } else if (groups.includes("Create")) {
        credentials.access = CREDENTIALS.Create;
      } else {
        credentials.access = CREDENTIALS.Read;
      }

      if (groups.includes("WebGL"))
        credentials.type = "WebGL";

      setCredentials(credentials);
      setAuthenticated(true);
    }
  } catch (e) {
    return;
  }
}

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [forceDashboardUpdate, setForceDashboardUpdate] = useState(false);
  const [credentials, setCredentials] = useState();

  const forceUpdate = () => {
    const force = forceDashboardUpdate;

    if (force)
      setForceDashboardUpdate(false);

    return force;
  }
  
  if (!authenticated) {
    autoLogIn(setAuthenticated, setCredentials);

    return (
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Login setAuthenticated={setAuthenticated} setCredentials={setCredentials} />} />
          <Route exact path='/forgotpassword' element={<ForgotPassword setAuthenticated={setAuthenticated} />} />
          <Route exact path='*' element={<Login setAuthenticated={setAuthenticated} setCredentials={setCredentials} />} />
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <div className='wrapper'>
      <BrowserRouter>
        <Header setAuthenticated={setAuthenticated} credentials={credentials} />
        <Routes>
          <Route exact path='/' element={<Dashboard forceDashboardUpdate={forceUpdate} credentials={credentials} />} />
          <Route exact path='/dashboard' element={<Dashboard forceDashboardUpdate={forceUpdate} credentials={credentials} />} />
          <Route exact path='/addlicense' element={<AddLicense setAuthenticated={setAuthenticated} setForceDashboardUpdate={setForceDashboardUpdate} credentials={credentials} />} />
          <Route exact path='/license/:key' element={<UpdateLicense setAuthenticated={setAuthenticated} setForceDashboardUpdate={setForceDashboardUpdate} credentials={credentials} />} />
          <Route exact path='/license/:key/:platform/:type' element={<DisplayApps setAuthenticated={setAuthenticated} setForceDashboardUpdate={setForceDashboardUpdate} credentials={credentials} />} />
          <Route exact path='/testlicense' element={<TestLicense setAuthenticated={setAuthenticated} credentials={credentials} />} />
          <Route exact path='/changelog' element={<Changelog/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
