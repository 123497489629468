import "./Dashboard.css";

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import logo  from '../../icons/neg_logo.png';

export default function License({license, currentTime, setCopied}) {
    const licenseTimelock = license.Timelock;
    const expired = currentTime - licenseTimelock >= 0;
    const dateOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric', 
        hour12: true,
        timeZone: 'GMT' // TODO: local timeZone could be used, but it should indicate the detected timezone
    };
    const platforms = !(license.Platforms instanceof Array) ? Object.keys(license.Platforms) : license.Platforms;

    return(
        <Link 
            className="license" 
            to = {"license/" + license.Key}
            state = {{license: license}}>
            <div className="license-info">
                <div className="key" onClick={e => {
                    // Copies the key of the license into the clipboard
                    e.preventDefault(); 
                    navigator.clipboard.writeText(e.target.innerText).then(() => {setCopied(true)});
                }}>
                    {license.Key}
                </div>
                <div className={!license.IsActive ? "inactive" : ""}>
                    {license.Customer}
                    {
                        license.Watermark ?
                            <img src={logo} className="watermark" data-tooltip-id="my-tooltip"
                            data-tooltip-content="Watermark enabled"/>
                        :
                        ""
                    }
                </div>
                <div className={expired ? "expired" : ""}>{new Date(licenseTimelock).toLocaleString('en-US', dateOptions)} GMT</div>
            </div>
            {
                <div className="additional-info">
                    {
                        platforms.length > 0 &&
                        <div className="platforms">
                        {
                            platforms.map(platform => {
                                return (<div className={"platform " + platform} key={platform}>
                                    <span className="platform-name">{platform}</span>
                                </div>)
                            })

                        }
                        </div>
                    }
                    {
                        license.MonthlyCounter !== undefined &&
                        <div className="impressions">{license.MonthlyCounter} Impressions</div>
                    }
                </div>
            }
        </Link>
    );
}

License.propTypes = {
    license: PropTypes.object.isRequired
}