import "./UpdateLicense.css";

import { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Auth } from "aws-amplify";
import * as XLSX from 'xlsx/xlsx.mjs';

import { availablePlatforms as availablePlatformsUtils, checkUser, CREDENTIALS, domainPlatforms, editorPlatforms, getBaseURL, retrieveDomain, timestampToDate, webPlatforms } from "../../utils/utils";
import LicenseDetails from "../LicenseDetails/LicenseDetails";
import { Tooltip } from "react-tooltip";
import Customer from "../Basic/Customer";
import LocalPlatforms from "../Platform/LocalPlatforms";

export default function DemoLicense({ setAuthenticated, setForceDashboardUpdate, credentials, license, licenseAppDomains }) {
    const licenseState = license || false;
    const navigate = useNavigate();
    const key = useParams()['key'];

    const [licenseDetails, setLicenseDetails] = useState();
    const [appDomains, setAppDomains] = useState();
    const [settingPlatform, setSettingPlatform] = useState(false);

    const [availablePlatforms, setAvailablePlatforms] = useState(availablePlatformsUtils);
    const platformsArray = licenseState ? licenseState.Platforms : [];
    const [platforms, setPlatforms] = useState(platformsArray);
    const [selectedPlatform, setSelectedPlatform] = useState(availablePlatforms[0]);

    const [oldTimelock, setOldTimelock] = useState();
    
    const [settingProdApp, setSettingProdApp] = useState(false);

    const [updating, setUpdating] = useState(false);

    const displayMandatoryValue = (name) => {
        if (name === "apps-container") {
            const element = document.getElementById(name);
            element.getElementsByTagName("label")[0].style.border = "2px solid red";
            element.getElementsByTagName("label")[0].style.borderRadius = "10px";
        } else {
            const element = document.getElementById(name);
            element.style.border = "2px solid red";
            
        }       
    }

    const displayErrorMessage = (message) => {
        const element = document.getElementById("error-message");
        element.innerHTML = "License could not be updated.<br>Reason:<br>" + message;
    }

    const clearErrorMessage = () => {
        document.getElementById("error-message").innerHTML = "";
    }

    const checkField = (name, value) => {
        const element = document.getElementById(name);

        switch (name) {
            case "customer-input":
                if (value === "") element.style.border = "2px solid red";
                else element.style.border = "";
                break;

            case "apps-container":
                if (value === true) element.getElementsByTagName("label")[0].style.border = "";
                break;
            default:
                break;
        }
    }

    const getLicenseDetails = async (key) => {
        try {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            const resp = await fetch(getBaseURL() + '/getlicense/' + key, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                }
            });

            if (resp.status === 200) {
                const licenseDetails = (await resp.json()).data;
                const appDomains = {
                    demo: {}, prod: {}
                };

                for (const SK of licenseDetails) {
                    if (SK['SK'] === "Details") {
                        setLicenseDetails(SK);

                        const newAvailablePlatforms = availablePlatforms.filter(platform => {
                            for (const oldPlatform of SK.Platforms) {
                                if (platform === oldPlatform)
                                    return false;
                            }

                            return true;
                        });
                        setAvailablePlatforms(newAvailablePlatforms);
                        setSelectedPlatform(newAvailablePlatforms[0]);
                        setPlatforms(SK.Platforms);

                        const oldTime = timestampToDate(SK.Timelock);
                        setOldTimelock(oldTime);
                    } else {
                        const type = SK["ProductionLicense"] ? "prod" : "demo";
                        const platform = SK["SK"].split('_', 1)[0];

                        if (!(platform in appDomains[type]))
                            appDomains[type][platform] = [];

                        appDomains[type][platform].push(SK);
                    }
                }

                setAppDomains(appDomains);           

                console.log('Key found', licenseDetails);
            } else {
                updateLicenseState(null);
                displayErrorMessage(resp.errorMessage + "<br>Please try again and, if the problem persists, contact with support.");
                console.error('Get licenseDetails error.', resp.status === 404 ? 'License not found' : 'Server error');
            }
        } catch (error) {
            updateLicenseState(null);
            displayErrorMessage("Unknown error:<br>" + error + "<br>Please contact with support if the problem persists.");
            console.error("Key could't be found", error);
        }
    }

    const updateLicense = async (licenseDetails, resetCounter, newApp) => {
        clearErrorMessage();
        
        let message = "";

        if (!licenseDetails.Customer || licenseDetails.Customer === "") {
            displayMandatoryValue("customer-input");
            message += "Customer field cannot be empty.<br>";
        }

        if (!licenseDetails.Timelock) {
            displayMandatoryValue("timelock-input");
            message += "Timelock must be set.<br>";
        }

        if (platforms.length === 0) {
            displayMandatoryValue("apps-container");
            message += "At least one platform must be selected<br>";
        }

        if (!licenseDetails.Customer || licenseDetails.Customer === "" || !licenseDetails.Timelock || platforms.length === 0) {
            displayErrorMessage(message);
            return;
        }

        if (resetCounter) {
            licenseDetails.MonthlyCounter = 0;
        }

        const updatedLicense = licenseDetails;

        updatedLicense.Timelock = new Date(licenseDetails.Timelock).getTime();

        if (licenseDetails.DurationLimit === "")
            updatedLicense.DurationLimit = undefined;

        // TODO: Small patch to set ResetRate to monthly, remove once the value is properly managed
        updatedLicense["ResetRate"] = 1;

        try {
            setUpdating(true);

            if (!await checkUser(setAuthenticated))
                return;

            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            await fetch(getBaseURL() + '/updatelicense', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                },
                body: JSON.stringify({
                    Details: updatedLicense,
                    AppDomains: newApp ? [newApp] : undefined
                })
            });

            setUpdating(false);
            navigate(".", { licenseDetails: updatedLicense });
            setForceDashboardUpdate(true);
            console.log('Key updated', updatedLicense);
        } catch (error) {
            console.error("Key could't be updated");
            displayErrorMessage("Couldn't connect to the server. Please try again and, if the problem persist, conctact with backend team.");
        } finally {
            setUpdating(false);
        }
    }

    const updateLicenseState = (licenseDetails) => {
        if (licenseDetails === null) {
            setLicenseDetails(null);
            return false;
        }

        setPlatforms(licenseDetails.Platforms);

        const newAvailablePlatforms = availablePlatforms.filter(platform => {
            for (const oldPlatform of licenseDetails.Platforms) {
                if (platform === oldPlatform)
                    return false;
            }

            return true;
        })
        setAvailablePlatforms(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);

        let oldDomains = {};
        for (const platform in licenseDetails.Platforms) {
            const domainsOnPlatform = [];

            oldDomains[platform] = domainsOnPlatform;
        }

        licenseDetails.Domains = oldDomains;

        setLicenseDetails(licenseDetails);
    }

    const checkTimelock = (event) => {
        const eventDate = new Date(event.target.value).getTime();

        if (eventDate === NaN) {
            const newCurrentTime = timestampToDate(new Date());
    
            setLicenseDetails({ ...licenseDetails, Timelock: newCurrentTime });
            event.target.value = newCurrentTime;
        } else {
            setLicenseDetails({ ...licenseDetails, Timelock: eventDate });
        }
    }

    const addPlatform = () => {
        const newPlatforms = [
            ...platforms,
            selectedPlatform
        ];

        if (licenseDetails.LocalPlatforms === undefined) {
            if (licenseState.Platforms instanceof Array)
                licenseDetails.LocalPlatforms = [...licenseState.Platforms];
            else
                licenseDetails.LocalPlatforms = [...Object.keys(licenseState.Platforms)];
        }

        const localPlatforms = [...licenseDetails.LocalPlatforms];

        if (!localPlatforms.includes(selectedPlatform))
        localPlatforms.push(selectedPlatform);
        
        if (webPlatforms.includes(selectedPlatform)) {
            if (!localPlatforms.includes("html5"))
            localPlatforms.push("html5");
        }
        
        if (editorPlatforms.includes(selectedPlatform)) {
            if (!localPlatforms.includes("windows"))
            localPlatforms.push("windows");       
            
            if (!localPlatforms.includes("macos"))
            localPlatforms.push("macos");            
        }

        setPlatforms(newPlatforms);
        setLicenseDetails({
            ...licenseDetails,
            Platforms: newPlatforms,
            LocalPlatforms: localPlatforms
        });

        const newAvailablePlatforms = availablePlatforms.filter(platform => {
            if (platform === selectedPlatform)
                return false;

            return true;
        });

        setAvailablePlatforms(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);
        setSettingPlatform(false);
    }

    const removePlatform = (removedPlatform) => {
        if (window.confirm("Note that this will remove both demo and production applications unavailable.")) {
            const newPlatforms = platforms.filter(platform => {
                if (platform === removedPlatform)
                    return false;
    
                return true;
            });
            setPlatforms(newPlatforms);
            setLicenseDetails({
                ...licenseDetails,
                Platforms: newPlatforms
            });
    
            const newAvailablePlatforms = availablePlatformsUtils.filter(
                platform => availablePlatforms.includes(platform) || platform === removedPlatform);
    
            setSelectedPlatform(newAvailablePlatforms[0]);
            setAvailablePlatforms(newAvailablePlatforms);
        }
    }

    const addProductionApp = (e, platform) => {
        e.preventDefault();
        setSettingProdApp(false);
        
        const form = new FormData(e.target).entries()
        const json = Object.fromEntries(form);

        if (!json["appDomain"] || json["appDomain"] === "")
            return;

        let appDom = json["appDomain"];

        if (domainPlatforms.includes(platform)) {
            appDom = retrieveDomain(json["appDomain"]);
        }

        const sk = platform + "_" + appDom;

        if (appDomains["demo"][platform]?.some(app => {
            return app.SK === sk
        })) {
            window.alert('This app already exists as a Demo, if you want to convert it to a Production app, enter into Demo apps and select "Move to production"');
            return;
        }

        if (appDomains["prod"][platform]?.some(app => {
            return app.SK === sk
        })) {
            window.alert("This app already exists on Production, please modify it by entering into Production apps");
            return;
        }

        if (!appDomains["prod"][platform])
            appDomains["prod"][platform] = [];

        const appDomain = {
            Key: licenseDetails.Key,
            SK: sk,
            ProductionLicense: true,
            Timelock: Date.now() + 31536000000 // Add 1 year to current date
        }

        appDomains["prod"][platform].push(appDomain);

        setAppDomains(appDomains);

        updateLicense(licenseDetails, false, appDomain);
    }

    const updateLimit = (newLimit, field) => {
        if (newLimit === "")
            setLicenseDetails({...licenseDetails, [field]: newLimit});
        else if (!isNaN(newLimit) && Number(newLimit) >= 0)
            setLicenseDetails({...licenseDetails, [field]: Number(newLimit)});
    }
    
    const handleDownload = () => {
        let formatedApps = [];
        let impressionCounter = 0;
        let maxDate = 0;
        let minDate = Number.MAX_VALUE;

        // Convert format
        for (const type of Object.keys(appDomains)) {
            for (const platform of Object.keys(appDomains[type])) {
                const newFormat = appDomains[type][platform].map(app => {
                    const [platform, appName] = app.SK.split(/_(.+)/, 2);
                    impressionCounter += app.Impressions;
                    if (app.LastImpression && maxDate < app.LastImpression.Date)
                        maxDate = app.LastImpression.Date;
                    if (app.FirstImpression && minDate > app.FirstImpression.Date)
                        minDate = app.FirstImpression.Date;

                    return {
                        "App": appName,
                        "Platform": platform,
                        "Impressions": app.Impressions,
                        "First Time": app.FirstImpression ? (new Date(app.FirstImpression.Date)).toLocaleString() : null,
                        "Last Time": app.LastImpression ? (new Date(app.LastImpression.Date)).toLocaleString() : null
                    };
                });

                formatedApps = formatedApps.concat(newFormat);
            }
        }

        formatedApps = formatedApps.sort((app1, app2) => {
            if (app1.Platform < app2.Platform)
                return -1;

            if (app1.Platform === app2.Platform) {
                if (app1.Impressions > app2.Impressions)
                    return -1;
                
                if (app1.Impressions === app2.Impressions)
                    return 0;
            }

            return 1;
        });

        console.log(formatedApps);

        const sheet = XLSX.utils.json_to_sheet(formatedApps);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sheet, `License ${licenseDetails.Key}`);
        XLSX.utils.sheet_add_aoa(sheet, [
            [
                'Total',
                '',
                impressionCounter,
                minDate !== Number.MAX_VALUE ? (new Date(minDate)).toLocaleString() : '',
                (new Date(maxDate)).toLocaleString()
            ]
        ], { origin: -1 });
        XLSX.writeFile(wb, `license_${licenseDetails.Key}_${Date.now()}.xlsx`);
    };

    // Sets the licenseDetails if found (redirected from the Dashboard) to avoid extra calls
    useEffect(() => {        
        if (!licenseState || !licenseAppDomains || licenseState.Key !== key) {
            getLicenseDetails(key);
        } else {
            setLicenseDetails(licenseState);

            const newAvailablePlatforms = availablePlatforms.filter(platform => {
                for (const oldPlatform of licenseState.Platforms) {
                    if (platform === oldPlatform)
                        return false;
                }

                return true;
            });
            setAvailablePlatforms(newAvailablePlatforms);
            setSelectedPlatform(newAvailablePlatforms[0]);
            setPlatforms(licenseState.Platforms);

            const oldTime = timestampToDate(licenseState.Timelock);
            setOldTimelock(oldTime);

            const oldAppDomains = {
                demo: {}, prod: {}
            };

            for (const SK of licenseAppDomains) {
                    const type = SK["ProductionLicense"] ? "prod" : "demo";
                    const platform = SK["SK"].split('_', 1)[0];
    
                    if (!(platform in oldAppDomains[type]))
                        oldAppDomains[type][platform] = [];
    
                    oldAppDomains[type][platform].push(SK);
            }

            setAppDomains(oldAppDomains);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (licenseDetails) {
        return (
            <Fragment>
                <div className="flex center">
                    <div id="title">LICENSE {key}</div>

                    <div>
                        {/* Download CSV */}
                        <div id="download-sheet" data-tooltip-id="my-tooltip"
                            data-tooltip-content="Downloads an XLSX file with the license's impressions" onClick={handleDownload}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
                            </svg>
                        </div>
                    </div>
                </div>

                <Customer checkField={checkField} name={licenseDetails.Customer} setLicense={(value) => {setLicenseDetails({...licenseDetails, Customer: value})}}/>

                <div id="timelock" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Date until which the demo apps will work">Global timelock</label>
                    <input id="timelock-input" type="datetime-local" name='timelock' min={oldTimelock} defaultValue={oldTimelock} onBlur={checkTimelock}></input>
                </div>

                <div id="duration-limit" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Allowed duration playback for demo apps">Duration limit</label>
                    <div id="duration-limit-div">
                        <input className="number-input" name='duration' placeholder="No limit" value={licenseDetails.DurationLimit} onChange={e => updateLimit(e.target.value, "DurationLimit")}></input>
                        seconds
                    </div>
                </div>

                <div id="impression-limit" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Limit of impressions for demo apps">Impression limit</label>
                    <input className="number-input" name='impression-limit' placeholder="No limit" value={licenseDetails.ImpressionLimit} onChange={e => updateLimit(e.target.value, "ImpressionLimit")}></input>
                </div>

                <div id="local-platforms-container" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Platforms where the playback will be enabled using local domains/apps (editors included)">Local Platforms</label>
                    <LocalPlatforms platforms={licenseDetails.LocalPlatforms} updatePlatforms={(localPlatforms) => setLicenseDetails({...licenseDetails, LocalPlatforms: localPlatforms})}/>
                </div>

                <div id="apps-container" className="input-container">
                    <label data-tooltip-id="my-tooltip"
                    data-tooltip-content="Enabled platforms">Platforms</label>
                    <Fragment>
                        {
                        platforms.length > 0 ? (
                        <table id="platforms">
                            <tbody>
                            {
                                platforms.map(platform => {
                                    return (
                                        <tr key={platform}>
                                            <td className={"platform " + platform}>{platform}</td>
                                            {/* Demo apps */}
                                            <td>
                                            {
                                                appDomains && appDomains["demo"][platform]?.length > 0 &&
                                                    <button className={`white-text`} onClick={() => navigate(`./${platform}/demo`, {
                                                        state:  {
                                                            licenseDetails: licenseDetails,
                                                            type: "Demo",
                                                            platform: platform,
                                                            appDomains: appDomains["demo"][platform],
                                                            otherApps: appDomains["prod"][platform]
                                                        }
                                                    })}>Demo apps</button>
                                                }
                                            </td>
                                            {/* Prod apps */}
                                            <td>
                                            {
                                                appDomains && appDomains["prod"][platform]?.length > 0 &&
                                                <button className={`white-text`} onClick={() => navigate(`./${platform}/prod`, {
                                                    state: {
                                                        licenseDetails: licenseDetails, 
                                                        type: "Production", 
                                                        platform: platform, 
                                                        appDomains: appDomains["prod"][platform],
                                                        otherApps: appDomains["demo"][platform]
                                                    }
                                                })}>Production apps</button>
                                            }
                                            </td>
                                            
                                            <td>
                                                <button className="delete-button" onClick={() => removePlatform(platform)}>Remove platform</button>
                                            </td>

                                            <td className="add-production">
                                                <button className="add-app-button" data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Add a production app manually" 
                                                    onClick={() => setSettingProdApp(settingProdApp === platform ? false : platform)}>Add production app</button>
                                                {
                                                    settingProdApp === platform &&
                                                    <form className="set-production-app" onSubmit={e => addProductionApp(e, platform)}>
                                                        <input type="text" name="appDomain"/>
                                                        <button type="submit">Confirm</button>
                                                    </form>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>) : (null)
                        }

                        {
                        availablePlatforms.length > 0 ?
                        <div id="platform-selector">
                            {
                                settingPlatform ?
                                    <Fragment>
                                        <select onChange={e => setSelectedPlatform(e.target.value)}>
                                            {
                                                availablePlatforms.map(platform => {
                                                    return (
                                                        <Fragment key={platform}>
                                                            <option value={platform}>{platform}</option>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </select>
                                        <button onClick={e => {checkField("apps-container", true); addPlatform();}}>Add</button>
                                        <button onClick={() => setSettingPlatform(false)}>Cancel</button>
                                    </Fragment>
                                :
                                    <button onClick={() => setSettingPlatform(true)}>Add platform</button>
                            }
                        </div> : (null) 
                        }
                    </Fragment>
                </div>

                <div id="watermark" className="checkbox-container">
                    <input type="checkbox" onChange={e => setLicenseDetails({ ...licenseDetails, Watermark: e.target.checked })} checked={licenseDetails.Watermark} />
                    <div data-tooltip-id="my-tooltip"
                    data-tooltip-content="If enabled, watermark will be displayed on all the apps">Watermark</div>
                </div>

                <div id="is-active" className={`checkbox-container${licenseDetails.IsActive ? "" : " license-disabled"}`}>
                    <input type="checkbox" onChange={e => setLicenseDetails({ ...licenseDetails, IsActive: e.target.checked })} checked={licenseDetails.IsActive} />
                    <div data-tooltip-id="my-tooltip"
                    data-tooltip-content="Disabling the license will prevent its usage on all apps, PRODUCTION APPS INCLUDED">Active</div>
                </div>

                <LicenseDetails licenseDetails={licenseDetails}></LicenseDetails>

                {
                    credentials.access >= CREDENTIALS.FullAccess &&
                    <div id="create-license">
                        <button id="update-button" className="button" onClick={(e) => updateLicense(licenseDetails)}>{updating ? 'Updating...' : 'Update'}</button>
                        <div id="error-message"></div>
                    </div>
                }
                <Tooltip id="my-tooltip" className="my-tooltip" multiline={true} />
            </Fragment>
        );
    } else if (licenseDetails === undefined) {
        return (
            <div>
                <h3>Loading license details...</h3>
            </div>
        )
    } else {
        return (
            <div>
                <h3>License not found</h3>
            </div>
        )
    }
}