import "./UpdateLicense.css";

import { useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { Auth } from "aws-amplify";

import ProductionLicense from "./ProductionLicense";
import DemoLicense from "./DemoLicense";
import { CREDENTIALS, getBaseURL } from "../../utils/utils";

export default function UpdateLicense({ setAuthenticated, setForceDashboardUpdate, credentials }) {
    const [license, setLicense] = useState(useLocation().state?.license || false);
    const [appDomains, setAppDomains] = useState();
    const key = useParams()['key'];

    const [loading, setLoading] = useState(false);

    const getLicenseDetails = async (key, setLicense) => {
        if (loading)
            return;

        setLoading(true);

        try {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();

            const resp = await fetch(getBaseURL() + '/getlicense/' + key, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                }
            });

            if (resp.status === 200) {
                const licenseDetails = (await resp.json()).data;
                const appDomains = [];

                for (const SK of licenseDetails) {
                    if (SK['SK'] === "Details") {
                        setLicense(SK);
                    } else {
                        appDomains.push(SK);
                    }
                }

                if (appDomains.length > 0)
                    setAppDomains(appDomains);

                console.log('Key found', licenseDetails);
            } else {
                setLicense(null);
                console.error('Get licenseDetails error.', resp.status === 404 ? 'License not found' : 'Server error');
            }
        } catch (error) {
            setLicense(null);
            console.error("Key could't be found", error);
        } finally {
            setLoading(false);
        }
    }

    if (license) {
        return (
        <div id={"update-license" + (credentials.access >= CREDENTIALS.FullAccess ? "" : " no-update")}>
        {(
                license.Type === "Demo" ?
                    <DemoLicense setAuthenticated={setAuthenticated} setForceDashboardUpdate={setForceDashboardUpdate} credentials={credentials} license={license} licenseAppDomains={appDomains}></DemoLicense>
                :
                    <ProductionLicense setAuthenticated={setAuthenticated} setForceDashboardUpdate={setForceDashboardUpdate} credentials={credentials} license={license} licenseAppDomains={appDomains}></ProductionLicense>
        )}
        </div>)
    } else if (license === false) {
        getLicenseDetails(key, setLicense)
        return (
            <div>
                <h3>Loading license details...</h3>
            </div>
        )
    } else {
        return (
            <div>
                <h3>License not found</h3>
            </div>
        )
    }
}