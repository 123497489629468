import React, { Fragment, useState } from "react";
import { Auth } from "aws-amplify";

import './ForgotPassword.css';
import { useNavigate } from "react-router-dom";

export default function ForgotPassword({ setAuthenticated }) {
    const [awaitingCode, setAwaitingCode] = useState(false);
    const [email, setEmail] = useState();
    const navigate = useNavigate();

    /** Asks for the code. It's sent to user's email. */
    const sendCode = async () => {
        const email = document.getElementById("email").value;
        setEmail(email);

        try {
            await Auth.forgotPassword(email);
            setAwaitingCode(true);
        } catch (e) {
            console.error("Error", e);
        }
    }

    /** Sends the code and the new password to reset it. */
    const changePassword = async () => {
        const code = document.getElementById("code").value;
        const password = document.getElementById("password").value;

        try {
            const data = await Auth.forgotPasswordSubmit(email, code, password);

            if (data === "SUCCESS") {
                navigate("/");
            } else {
                console.error("Error changing the password", data);
            }
        } catch (e) {
            console.error("Error changing the password", e);
        }
    }

    /** Enables/disables the visibility of the password. */
    const switchVisibility = () => {
        const passwordElement = document.getElementById("password");

        if (passwordElement.type === "password") {
            passwordElement.type = "text";
        } else {
            passwordElement.type = "password";
        }
    }

    return (
        <div className="reset-password">
            <h1>Set your email. We will send you a confirmation code to the email to change your password</h1>
            <div>
                {
                    !awaitingCode ?

                        <Fragment>
                            <input id="email" type="text"></input>
                            <button className="submit" onClick={sendCode}>Send code</button>
                        </Fragment> :

                        <Fragment>
                            <label>{email}</label>
                            <div>
                                <label>Your code</label>
                                <input id="code" type="text"></input>
                            </div>
                            <div>
                                <label>New password</label>
                                <input id="password" type="password"></input>
                                <input type="checkbox" onClick={() => switchVisibility()}/>Show password
                            </div>
                            <button className="submit" onClick={changePassword}>Set new password</button>
                        </Fragment>
                }
            </div>
        </div>
    );
}