import "./AppDomain.css";

import { useState, useEffect } from "react";
import LastImpression from "../LastImpression/LastImpression";

export default function DemoApp({ appDomain, impressionLimit }) {
    const [name] = useState(appDomain.SK.split(/_(.+)/)[1]);
    const [moving, setMoving] = useState(false);
    const [, setState] = useState();

    useEffect(() => {
    }, []);

    const forceUpdate = () => setState({});

    const switchDisable = () => {
        appDomain["IsActive"] = appDomain["IsActive"] !== undefined ? !appDomain["IsActive"] : false;
        forceUpdate();
    };

    const moveToProduction = () => {
        appDomain["ProductionLicense"] = moving ? undefined : true;
        let nextYear = new Date();
        appDomain["Timelock"] = moving ? undefined : nextYear.setFullYear(nextYear.getFullYear() + 1);
        setMoving(!moving);
    };

    if (appDomain) {
        return (
            <tr className="app-domain">
                <td className="name">{name}</td>
                <td className={`impressions ${impressionLimit && appDomain.Impressions >= impressionLimit ? "limit" : ""}`}>{appDomain.Impressions} Impression{appDomain.Impressions > 1 ? "s" : ""}</td>

                <td className="daily-impressions">
                    <span>
                        {appDomain.DailyImpressions ? appDomain.DailyImpressions : ''}
                    </span>
                </td>

                {/* <button className={"timelock-button " + (appDomain.Timelock !== "undefined" ? "enabled" : "disabled")}
                                    onClick={() => switchTimelock()}>Timelock</button>
                {
                timelockEnabled &&
                    <input type="datetime-local" name='timelock' value={timestampToDate(appDomain.Timelock)}
                        onChange={e => updateTimelock(e.target.value)}/>
                } */}

                <td className="first-impression">
                    <span>
                        {appDomain.FirstImpression ? new Date(appDomain.FirstImpression.Date).toGMTString() : ''}
                    </span>
                </td>

                <td className="last-impression">
                    <span>
                        {appDomain.LastImpression ? new Date(appDomain.LastImpression.Date).toGMTString() : ''}
                    </span>
                </td>

                {
                    name !== "Local" &&
                    <td>
                        <button className={`disable ${appDomain.IsActive === false ? "disabled" : ""}`} onClick={switchDisable}>{appDomain.IsActive === false ? "Disabled" : "Disable"}</button>
                    </td>
                }

                {
                    name !== "Local" &&
                    <td>
                        <button className={`to-production ${moving === true ? "disabled" : ""}`} onClick={moveToProduction}>{moving === true ? "Cancel" : "Move to production"}</button>
                    </td>
                }
            </tr>
        );
    } else {
        return ""
    }
}