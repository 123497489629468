import "./AppDomain.css";

import { useState, useEffect } from "react";
import { timestampToDate } from "../../../utils/utils";
import LastImpression from "../LastImpression/LastImpression";

export default function ProductionApp({ appDomain }) {
    const [name] = useState(appDomain.SK.split(/_(.+)/)[1]);
    const [, setState] = useState();
    const [currentImpressions] = useState(appDomain.Impressions);
    const [currentLimit] = useState(appDomain.ImpressionLimit);

    useEffect(() => {
    }, []);

    const forceUpdate = () => setState({});

    const switchDisable = () => {
        appDomain["IsActive"] = appDomain["IsActive"] !== undefined ? !appDomain["IsActive"] : false;
        forceUpdate();
    };

    const switchWatermark = (id) => {
        appDomain["Watermark"] = !appDomain["Watermark"];
        forceUpdate();
    };

    const updateTimelock = (timelock) => {
        if (!timelock)
            return;

        appDomain["Timelock"] = new Date(timelock).getTime();
        forceUpdate();
    }

    const switchTracking = () => {
        appDomain["Impressions"] = appDomain["Tracking"] ? undefined : currentImpressions;
        appDomain["ImpressionLimit"] = appDomain["Tracking"] ? undefined : currentLimit;
        appDomain["ResetRate"] = appDomain["Tracking"] ? undefined : 1;
        appDomain["Tracking"] = !appDomain["Tracking"];
        forceUpdate();
    }

    const updateLimit = (newLimit, field) => {
        if (!isNaN(newLimit)) {
            appDomain[field] = newLimit;

            forceUpdate();
        }
    }

    if (appDomain) {
        return (
            <tr className="app-domain">
                <td className="name">{name}</td>

                <td>
                    <button className={`disable-button ${appDomain.IsActive === false ? "disabled" : ""}`} onClick={switchDisable}>{appDomain.IsActive === false ? "Disabled" : "Disable"}</button>
                </td>

                <td data-label="Tracking" className={`tracking checkbox-container${appDomain.IsActive === false ? " hidden" : ""}`}>
                    <input type="checkbox" onChange={switchTracking} checked={appDomain.Tracking} />
                </td>

                <td>
                    <div className={`impressions${appDomain.Tracking !== true || appDomain.IsActive === false ? " hidden" : ""}`}>{appDomain.Impressions} Impression{appDomain.Impressions > 1 ? "s" : ""}</div>
                </td>

                <td className="daily-impressions">
                    <span>
                        {appDomain.DailyImpressions ? appDomain.DailyImpressions : ''}
                    </span>
                </td>

                <td data-label={appDomain.Tracking ? "Impression limit" : ""}>
                    {
                        <input className={`impression-limit${appDomain.Tracking !== true || appDomain.IsActive === false ? " hidden" : ""}`} type="text" placeholder="Impression limit" value={appDomain.ImpressionLimit}
                            onChange={e => updateLimit(e.target.value, "ImpressionLimit")} />
                    }
                </td>

                <td className="first-impression">
                    <span>
                        {appDomain.FirstImpression ? new Date(appDomain.FirstImpression.Date).toGMTString() : ''}
                    </span>
                </td>

                <td className="last-impression">
                    <span>
                        {appDomain.LastImpression ? new Date(appDomain.LastImpression.Date).toGMTString() : ''}
                    </span>
                </td>

                <td data-label="Timelock">
                    <input className="timelock" type="datetime-local" name='timelock' defaultValue={timestampToDate(appDomain.Timelock)}
                        onBlur={e => updateTimelock(e.target.value)} />
                </td>

                <td data-label="Duration limit">
                    <div className="row">
                        <input className={`duration-limit`} type="text" placeholder="No limit" value={appDomain.DurationLimit}
                            onChange={e => updateLimit(e.target.value, "DurationLimit")} />
                        seconds
                    </div>
                </td>

                <td>
                    <button className={"watermark-button " + (appDomain.Watermark === true ? "enabled" : "disabled")}
                        onClick={() => switchWatermark()}>Watermark</button>
                </td>
            </tr>
        );
    } else {
        return ""
    }
}