import React from "react";
import { Auth } from "aws-amplify";

import './Logout.css';

async function logout (setAuthenticated) {
    console.log('Sign out');
    await Auth.signOut();
    setAuthenticated(false)
}

export default function Logout({setAuthenticated}) {
    return (
        <button id="logout" type="button" onClick={e => logout(setAuthenticated)}>Log out</button>
    );
}