import "./TestLicense.css";

import CryptoJS from "crypto-js";

import { Fragment, useState, useEffect } from "react";

import { availablePlatforms as availablePlatformsUtils, checkUser } from "../../utils/utils";

// 0 - Invalid appDomain
// 1 - No license provided
// 2 - Timelock expired
// 3 - License not found
// 4 -  Bad Request (Malformed)
// 5 - No appDomain registered for this platform
// 6 - License is disabled

const parseErrorCode = (errorCode) => {
    switch (errorCode) {
        case 0:
            return "Invalid appDomain";
        case 1:
            return "No license provided";
        case 2:
            return "Timelock expired";
        case 3:
            return "License not found";
        case 4:
            return "Bad request";
        case 5:
            return "No appDomain registered for this platform";
        case 6:
            return "License disabled";
        default:
            return "No error";
    }
}

export default function TestLicense({ setAuthenticated }) {
    const [response, setResponse] = useState();
    const [devMode, setDevMode] = useState(false);

    const testLicense = async () => {
        if (!await checkUser(setAuthenticated))
            return;

        const licenseKey = {
            licenseKey: document.getElementById('key').value,
            platform: document.getElementById('platform').value,
            appDomain: document.getElementById('appDomain').value,
            from: "TestPage"
        }

        const message = JSON.stringify(licenseKey);
        var encrypted = CryptoJS.AES.encrypt(message, 'SuperSecretPasswordThatNeedsToBeOnTheClient!!');

        const key = await fetch(`https://licenses.hisplayer.com/${devMode ? 'dev/' : ''}licensecheck`, {
            method: 'POST',
            mode: 'cors',
            body: encrypted.toString()
        });

        const resp = await key.json();

        setResponse(resp);

        console.log("Test", resp);
    }

    useEffect(() => {
        let lastLetter;

        const refreshLastLetter = () => {
            setTimeout(() => {
                lastLetter = undefined;
            }, 5000);
        }

        function handleKeyDown(e) {
            switch (e.key) {
                case "1":
                    lastLetter = "1";
                    refreshLastLetter();
                    break;
                case "2":
                    lastLetter = lastLetter === "1" ? "2" : undefined;
                    break;
                case "d":
                    lastLetter = lastLetter === "2" ? "d" : undefined;
                    break;
                case "e":
                    lastLetter = lastLetter === "d" ? "e" : undefined;
                    break;
                case "v":
                    if (lastLetter === "e") {
                        lastLetter = "v";
                        setDevMode(true);
                    } else {
                        lastLetter = undefined;
                    }
                    break;
                default:
                    lastLetter = undefined;
                    break;
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        // Don't forget to clean up
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    return (
        <div id="panel">
            <h1 id="title">Test License</h1>
            {
                devMode &&
                <div id="dev-mode">DEV MODE</div>
            }
            <div id="test-form" onSubmit={testLicense}>
                <div className="field">
                    <label>Key</label>
                    <input id="key"></input>
                </div>

                <div className="field">
                    <label>Platform</label>
                    <select id="platform">
                        {
                            availablePlatformsUtils.map(platform => {
                                return (
                                    <Fragment key={platform}>
                                        <option value={platform}>{platform}</option>
                                    </Fragment>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="field">
                    <label>AppDomain</label>
                    <input id="appDomain"></input>
                </div>

                <button onClick={testLicense}>Test</button>
            </div>
            {
                response &&
                <div id="response" className={response.shouldPlay ? "active" : "inactive"}>
                    <label>Ref Id:</label>
                    <div>{response.refId}</div>

                    {
                        response.shouldPlay &&
                        <div id="additional-parameters">
                            Additional parameters:
                            {
                                Object.keys(response).map(key => {
                                    if (key !== "shouldPlay" && key !== "refId")
                                        return <label key={key}>{key}: {response[key]}</label>
                                    else
                                        return ""
                                })
                            }
                        </div>
                    }
                    {
                        !response.shouldPlay &&
                        <div id="error-code">
                            <label>Error Code: {response.errorCode}</label>
                            <div>{parseErrorCode(response.errorCode)}</div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}