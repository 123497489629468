import "./AddLicense.css";

import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

import AddProductionLicense from "./AddProductionLicense";
import AddDemoLicense from "./AddDemoLicense";
import demoIcon from '../../icons/demo.png';
import prodIcon from '../../icons/production.png';

export default function AddLicense({setAuthenticated, setForceDashboardUpdate, credentials}) {
    const [prod, setProd] = useState();

    if (prod === undefined) {
        return (
            <div id="select-license">
                <div className="license-selection production" onClick={() => setProd(true)} 
                data-tooltip-id="add-license-tooltip"
                data-tooltip-content='Production licenses allow to set the specific apps that are allowed on a license. 
                No other apps will be allowed. (Local domains like "localhost" are allowed automatically). 
                Recommended to use to provide licenses to individual customers. Avoid to use with packages published on the stores.'>
                    <img src={prodIcon} alt=""/>
                    <div>Production</div>
                </div>
                <div className="license-selection demo" onClick={() => setProd(false)}
                data-tooltip-id="add-license-tooltip"
                data-tooltip-content='Demo licenses allow to set platforms where the license will work.
                Any app will work on that platform and will appear in the list of apps of that license as a "demo app".
                All the demo apps will share the same attributes as the license itself (timelock, watermark, etc).
                Demo apps can be moved to production, in which case its own timelock and watermark can be set, 
                working independently from the license itself. This means the app will still work as long the timelock of the app is up.
                Use this license for demos that are going to be published on stores like Unity Asset Store.
                '>
                    <img src={demoIcon} alt=""/>
                    <div>Demo</div>
                </div>
                <Tooltip id="add-license-tooltip" place="bottom" multiline={true} />
            </div>
        )
    } else {
        return (
            <div>
                {
                    prod ?
                        <AddProductionLicense setAuthenticated={setAuthenticated} setForceDashboardUpdate={setForceDashboardUpdate} credentials={credentials}/> : 
                        <AddDemoLicense setAuthenticated={setAuthenticated} setForceDashboardUpdate={setForceDashboardUpdate} credentials={credentials}/>
                }
            </div>
        );
    }
}