import "./LastImpression.css";

export default function LastImpression({ lastImpression }) {
    return (
        <table id="last-impression-container">
            <tbody>
                <tr>
                    <td>Date</td>
                    <td>{new Date(lastImpression.Date).toGMTString()}</td>
                </tr>
                <tr>
                    <td>App</td>
                    <td>{lastImpression.AppDomain}</td>
                </tr>
                <tr>
                    <td>Platform</td>
                    <td>{lastImpression.Platform}</td>
                </tr>

            </tbody>
        </table>
    )
}