import { Auth } from 'aws-amplify';
import pjson from '../../package.json'

export const availablePlatforms = [
    'html5',
    'ps4',
    'ps5',
    'xbox',
    'tizen',
    'webos',
    'hisense',
    'android',
    'ios',
    'windows',
    'macos',
    'chromecast',
    'visionos'
];

// Platforms related to Unity/Unreal
export const editorPlatforms = [
    'html5',
    'windows',
    'ios',
    'macos',
    'android',
    'visionos'
];

// Platforms related to web environments
export const webPlatforms = [
    'html5',
    'tizen',
    'ps4',
    'ps5',
    'xbox',
    'webos',
    'hisense',
    'chromecast'
];

// Platforms that use domains (and so, can use subdomains)
export const domainPlatforms = [
    'html5',
    'ps4',
    'ps5',
];

export const CREDENTIALS = {
    Read: 0,
    Create: 1,
    FullAccess: 2,
    Admin: 3
}

export const timestampToDate = (timestamp) => {
    // Make sure there're no seconds nor ms
    const noSeconds = new Date(timestamp).setSeconds(0, 0);
    const now = new Date(noSeconds);
    const utcString = now.toISOString().substring(0,19);
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hour = now.getHours();
    const minute = now.getMinutes();
    const localDatetime = year + '-' +
        (month < 10 ? '0' + month.toString() : month) + '-' +
        (day < 10 ? '0' + day.toString() : day) + 'T' +
        (hour < 10 ? '0' + hour.toString() : hour) + ':' +
        (minute < 10 ? '0' + minute.toString() : minute) +
        utcString.substring(16,19);

    return localDatetime;
}

export const checkUser = async (setAuthenticated) => {
    try {
        const user = await Auth.currentUserInfo();
        
        if (Object.keys(user).length === 0) {
            console.error('Credential error, please log in again.');
            alert('Credential error, please log in again.');

            await Auth.signOut();
            setAuthenticated(false);
            
            return false;
        }
    } catch (e) {
        console.error('User not logged', e);
        alert('Crentials not found, please log in again.');

        await Auth.signOut();
        setAuthenticated(false);
        return false;
    }

    return true;
}

export const retrieveDomain = (domain) => {
    // eslint-disable-next-line
    const html5Regex = /(?:https?:\/\/)?(?:www2?.)?([^\/:]+)/;

    return html5Regex.exec(domain)[1];
}

export const retrieveDomains = (domains) => {
    // eslint-disable-next-line
    const html5Regex = /(?:https?:\/\/)?(?:www2?.)?([^\/:]+)/;

    return domains.map(domain => {
        return { ...domain, text: html5Regex.exec(domain.text)[1] };
    });
}

export const appDomainToPlatform = (appDomains) => {
    const domainsArray = [];
    
    for (let i = 0; i < appDomains.length; i++) {
        domainsArray.push({
            id: i,
            text: appDomains[i].SK.split(/_(.+)/)[1],
            timelock: appDomains[i].Timelock,
            watermark: appDomains[i].Watermark,
            subdomains: appDomains[i].AllowSubdomains
        });
    }

    return domainsArray;
}

export const platformToAppDomain = (domains, platform, key) => {
    const domainsArray = [];

    for (let i = 0; i < domains.length; i++) {
        if (domains[i].text !== '') {
            domainsArray.push({
                Key: key,
                SK: (platform + '_' + domains[i].text),
                Timelock: domains[i].timelock,
                Watermark: domains[i].watermark,
                AllowSubdomains: domains[i].subdomains
            });
        }
    }

    return domainsArray;
}

export const getBaseURL = () => {
    const env = (process.env.REACT_APP_API_STAGE === 'production' || process.env.REACT_APP_API_STAGE !== 'development') ? '' : '/dev';

    return 'https://licenses.hisplayer.com' + env;
}

export const getVersion = () => {
    return `V${pjson.version}`
}

/**
 * Converts an array of platforms (strings) to a map (Platform (string) => Info (object))
 * @param {Array} oldPlatforms 
 */
export const platformAdapter = (oldPlatforms) => {
    if (!(oldPlatforms instanceof Array))
        return oldPlatforms;

    const newPlatforms = [];

    for (const platform of oldPlatforms) {
        newPlatforms[platform] = {};
    }

    return newPlatforms;
}