import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import { CREDENTIALS } from "../../utils/utils";

import './Login.css';

async function signIn(username, password, setNewPassword, setErrorMessage, setCredentials) {
    try {
        const user = await Auth.signIn(username, password);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            setNewPassword(user);
            return;
        }

        if (user.attributes.email_verified) {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];

            let credentials = {};

            if (groups.includes("Admin")) {
                credentials.access = CREDENTIALS.Admin;
            } else if (groups.includes("FullAccess")) {
                credentials.access = CREDENTIALS.FullAccess;
            } else if (groups.includes("Create")) {
                credentials.access = CREDENTIALS.Create;
            } else {
                credentials.access = CREDENTIALS.Read;
            }

            if (groups.includes("WebGL"))
                credentials.type = "WebGL";

            setCredentials(credentials);
            
            return true;
        }

        return false;
    } catch (error) {
        console.error('Error signing in', error.message);
        setErrorMessage(error.message);

        return false;
    }
}

export default function Login({setAuthenticated, setCredentials }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [newPassword, setNewPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();

        setErrorMessage(undefined);

        if (!username || !password) {
            setErrorMessage('Please provide username and password');

            return;
        }

        let token;
        if (newPassword) {
            token = await Auth.completeNewPassword(newPassword, password);
        }

        token = await signIn(username, password, setNewPassword, setErrorMessage, setCredentials);

        if (token) {
            setAuthenticated(true);
        }
    };

    const forgotPassword = () => {
        navigate("/forgotpassword");
    }

    return (
        <div className="login-wrapper">
            <h1>Please Log In</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Username</p>
                    <input type="text" onChange={e => setUserName(e.target.value)}/>
                </label>
                <label>
                    <p>Password</p>
                    <input type="password" onChange={e => setPassword(e.target.value)}/>
                    {
                        newPassword &&
                        <label color="red">Please enter a new password</label>
                    }
                </label>
                <div>
                    <button className="submit" type="submit">Sign In</button>
                </div>
                <div onClick={forgotPassword}>Forgot password</div>
            </form>
            {
            errorMessage &&
            <div className="error-message">{errorMessage}</div>
            }
        </div>
    );
}

Login.propTypes = {
    setAuthenticated: PropTypes.func.isRequired
}