import "./Dashboard.css";

import React, { useCallback, useEffect, useState } from "react";

export default function LastRefresh({ refresh, lastRefresh }) {
    const [timeDisplay, setTimeDisplay] = useState();
    const [interval, setInter] = useState();
    const [refreshing, setRefreshing] = useState(false);
    
    const updateTime = useCallback((lastRefresh) => {
        const currentTime = Date.now();
        const timeDiff = ((currentTime - lastRefresh) / 1000).toFixed(0);
        const minutes = Math.floor(timeDiff / 60);
        setTimeDisplay(minutes > 0 ? `${minutes} minutes` : `less than 1 minute`);

        if (minutes > 10)
            refresh();
        // eslint-disable-next-line
    }, [lastRefresh]);

    useEffect(() => {
        clearInterval(interval);
        updateTime(lastRefresh);
        setRefreshing(false);
        
        const newInterval = setInterval(() => updateTime(lastRefresh), 1000);
        setInter(newInterval);

        return () => {
            clearInterval(newInterval);
            setInter(undefined);
        }
        // eslint-disable-next-line
    }, [lastRefresh]);

    return <div className="refresh">
        <button className="button" type="button" onClick={() => {refresh(); setRefreshing(true);}}>Refresh</button>
        <div className="refresh-time">{refreshing ? "Refreshing..." : `Last refresh ${timeDisplay} ago`}</div>
    </div>
}