import "./Dashboard.css";

import React, { useState, useEffect, useCallback, useReducer, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import License from "./License";
import { Auth } from "aws-amplify";

import deleteIcon  from '../../icons/delete.svg';
import rightArrow  from '../../icons/right-arrow.png';
import { availablePlatforms, CREDENTIALS, getBaseURL } from "../../utils/utils";
import LastRefresh from "./LastRefresh";
import { Tooltip } from "react-tooltip";

function reducer(state, action) {
    switch (action.type) {
        case "customer":
            return { ...state, customer: action.value };
        case "type":
            return { ...state, type: action.value };
        case "expired":
            return { ...state, expired: action.value }
        case "platforms":
            return { ...state, platforms: action.value }
        default:
            console.error("Filter error. Please contact with backend team");
            break;
    }
};

export default function Dashboard({ forceDashboardUpdate, credentials }) {
    const [lastKey, setLastKey] = useState('');
    const [licenses, setLicenses] = useState();
    const [licensesToDisplay, setLicensesToDisplay] = useState();
    const [loadingLicenses, setLoading] = useState(false);
    const [noPermissions, setNoPermissions] = useState(false);
    const [copied, setCopied] = useState(false);
    const [filters, dispatch] = useReducer(reducer, {type: "All", expired: "All", platforms: "All"});
    const [filtersOpened, setFiltersOpened] = useState(false);

    const navigate = useNavigate();

    const getLicenses = useCallback(async (loadNext) => {
        try {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            let url = (getBaseURL() + '/getlicenses' +
                (credentials.type === 'WebGL' ? '/WebGL' : '') + (loadNext ? lastKey : ''));

            setLoading(true);

            const resp = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                },
            });

            if (resp.status === 500) {
                setNoPermissions(true);
                sessionStorage.removeItem("licenses");
                console.error('No permissions.');

                return;
            }
            
            const parsedResp = await resp.json();
            const newLicenses = await JSON.parse(parsedResp.data);
    
            if (parsedResp.lastEvaluatedKey)
                setLastKey(`?lastEvaluatedKey=${parsedResp.lastEvaluatedKey?.Key}`);
            else
                setLastKey(undefined);

            if (licenses) {
                if (loadNext) {
                    licenses.push(...newLicenses);
                    setLicenses(licenses);
                    setLicensesToDisplay(licenses);
                    sessionStorage.setItem("licenses", await JSON.stringify(licenses));
                } else {
                    setLicenses([...newLicenses]);
                    setLicensesToDisplay([...newLicenses]);

                    sessionStorage.setItem("licenses", await JSON.stringify(newLicenses));
                }
            } else {
                setLicenses(newLicenses);
                setLicensesToDisplay(newLicenses);
                sessionStorage.setItem("licenses", parsedResp.data);
            }

            sessionStorage.setItem("lastRefresh", Date.now());
        } catch (e) {
            console.error('License error', e);
        }

        setLoading(false);
    }, [credentials.type, lastKey, licenses]);

    const searchLicense = (event) => {
        event.preventDefault();
        const key = event.target["searchtext"].value.toLowerCase();
        navigate(`/license/${key}`);
    };

    const filterLicenses = useCallback((filter) => {
      let newLicenses = licenses;

      if (filter.customer) {
          const filterLC = filter.customer.toLowerCase();
          newLicenses = licenses.filter(license => {
              return license.Customer.toLowerCase().includes(filterLC) || license.Key.toLowerCase().includes(filterLC);
          });
      }
      
      if (filter.expired && filter.expired !== "All") {
          if (filter.expired === "NotAvailable") {
              newLicenses = newLicenses.filter(license => {
                  return Date.now() - license.Timelock >= 0 || !license.IsActive;
              });
          } else if (filter.expired === "Expired") {
              newLicenses = newLicenses.filter(license => {
                  return Date.now() - license.Timelock >= 0;
              });
          } else if (filter.expired === "Inactive") {
              newLicenses = newLicenses.filter(license => {
                  return !license.IsActive;
              });
          } else {
              newLicenses = newLicenses.filter(license => {
                  return Date.now() - license.Timelock < 0 && license.IsActive;
              });
          }
      }

      
      if (filter.type && filter.type !== "All") {
          const demo = filter.type === "Demo";

          newLicenses = newLicenses.filter(license => {
              return (license.Type === "Demo") === demo
          });
      }

      if (filter.platforms && filter.platforms !== "All") {
          newLicenses = newLicenses.filter(license => {
            console.log("Key", license.Key, license.Platforms);
            if (filter.Platforms instanceof Array) {
              return filter.platforms.every(platform => license.Platforms.includes(platform));
            } else {
              return filter.platforms.every(platform => platform in license.Platforms);
            }
          });
      }

      setLicensesToDisplay(newLicenses);
    }, [licenses]);

    const deleteLicense = async(key, type) => {
        if (type === "Demo") {
            window.alert("For security reasons, demo licenses cannot currently be deleted. " +
             "Please contact with backend team in case you want to remove this license.");
        } else if (window.confirm(`Please confirm you want to delete "${key}"`)) {
            try {
                const session = await Auth.currentSession();
                const token = session.getIdToken().getJwtToken();
    
                setLoading(true);
    
                const resp = await fetch(getBaseURL() + '/updatelicense', {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Lic-Mag': token
                    },
                    body: JSON.stringify({key: key})
                });

                if (resp.status === 200) {
                    console.log("Key", key, "deleted");
                    getLicenses();
                } else {
                    console.error("Key couldn't be deleted")
                }
            } catch (e) {
                console.error('License error', e);
            }
        }
    };

    const filterPlatform = (platform, value) => {
        let newFilter = "All";

        if (value) {
            if (filters.platforms === "All")
                newFilter = [];
            else
                newFilter = filters.platforms;
            
            newFilter.push(platform);
        } else {
            if (filters.platforms !== "All")
                newFilter = filters.platforms.filter(plat => plat !== platform);
            
            if (newFilter.length === 0)
                newFilter = "All";
        }

        dispatch({ type: "platforms", value: newFilter });
    }

    const sessionLicenses = JSON.parse(sessionStorage.getItem("licenses"));

    useEffect(() => {
        if (forceDashboardUpdate()) {
            getLicenses();
        }

        if (!licenses) {
            if (sessionLicenses) {
                setLicenses(sessionLicenses);
                setLicensesToDisplay(sessionLicenses);
            }
            else
                getLicenses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (copied === true) {
            setTimeout(() => {
                setCopied(false);
            }, 5000);
        }
    }, [copied]);

    useEffect(() => {
        filterLicenses(filters);
    }, [filters, filterLicenses]);

    
    
    if (licensesToDisplay) {
        const currentTime = Date.now();

        return (
            <div id="dashboard">
                <div id="title">License dashboard</div>

                <form id="search-bar" onSubmit={searchLicense}>
                    <input name="searchtext" type="text" placeholder="Search by license key or filter by customer" onChange={e => dispatch({ type: "customer", value: e.target.value})} autoComplete="off"/>
                </form>

                <div id="filters">
                    <div id="filter-button" onClick={() => setFiltersOpened(!filtersOpened)}>
                        <img id="arrow" className={filtersOpened ? "open" : ""}  src={rightArrow}/>
                        <label id="filter-text" >Filters</label>
                    </div>
                    {
                        filtersOpened &&
                        <div id="filters-selectors">
                            <div id="expirity-selector">
                                <label className="selector-label">Availability</label>
                                <select onChange={e => dispatch({ type: "expired", value: e.target.value })}>
                                    <option value="All">All</option>
                                    <option value="Available">Available</option>    
                                    <option value="NotAvailable">Not Available</option>
                                    <option value="Expired">Expired</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>

                            <div id="type-selector">
                                <label className="selector-label">Type</label>
                                <select onChange={e => dispatch({ type: "type", value: e.target.value })}>
                                    <option value="All">All</option>
                                    <option value="Demo">Demo</option>
                                    <option value="Production">Production</option>
                                </select>
                            </div>

                            <div id="platform-selector">
                                <label className="selector-label">Platforms</label>
                                <div id="platform-options">
                                    {
                                        availablePlatforms.map((platform) => {
                                            return <div key={`option-${platform}`} className="platform-option"><input type="checkbox" onChange={e => filterPlatform(platform, e.target.checked)}></input> {platform}</div>
                                        }) 
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
                
                <div className="buttons">
                    <LastRefresh refresh={e => getLicenses(false)} lastRefresh={sessionStorage.getItem("lastRefresh")}/>
                    {
                        credentials.access >= CREDENTIALS.Create &&
                        <Link to={"addlicense"} className="button">Add license</Link>
                    }
                </div>
                {
                    licensesToDisplay && licensesToDisplay.length > 0 &&
                    <div id="licenses">
                    {
                        licensesToDisplay.map(license => {
                            return (
                            <div className="license-container" key={license.Key}>
                                <License license={license} currentTime={currentTime} setCopied={setCopied}/>
                                {
                                    credentials.access >= CREDENTIALS.FullAccess &&
                                    <img className="delete" src={deleteIcon} alt="p" onClick={e => {deleteLicense(license.Key, license.Type)}}></img>                                
                                }
                            </div>)
                        })
                    }
                    </div>
                }

                {
                    lastKey && 
                    <button className="load-next" onClick={e => getLicenses(true)}>{loadingLicenses ? 'Loading...' : 'Load more licenses'}</button>
                }

                {
                    copied &&
                    <div id="copied-message">Copied to clipboard!</div>
                }
                <Tooltip id="my-tooltip" className="my-tooltip" multiline={true} />
            </div>
        );
    }
        
    if (noPermissions) {
        return (<h2>You don't have permission to access the dashboard, 
        please contact with
        <a className="support-link" href="mailto:adrian.ogayar@hisplayer.com?subject=HisPlayer Dashboard credentials" target="_blank" rel="noreferrer">
        support</a>.</h2>)
    }
    
    return (<h2>Loading licenses...</h2>)
}