import "./AddLicense.css";

import React, { Fragment, useCallback, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import Platform from "../Platform/Platform";
import { availablePlatforms as availablePlatformsUtils, checkUser, editorPlatforms, getBaseURL, platformToAppDomain, retrieveDomains, timestampToDate, webPlatforms } from "../../utils/utils";
import { Tooltip } from "react-tooltip";
import Customer from "../Basic/Customer";
import LocalPlatforms from "../Platform/LocalPlatforms";

export default function AddProductionLicense({setAuthenticated, setForceDashboardUpdate, credentials}) {
    const [license, setLicense] = useState({
        Customer: '',
        Timelock: timestampToDate(new Date()),
        Platforms: {},
        ProductionLicense: false,
        Watermark: false,
        IsActive: true,
        Type: credentials.type === 'WebGL' ? 'WebGL' : 'Basic',
        LocalPlatforms: []
    });
    const [appDomains, setAppDomains] = useState({});

    const [platforms, setPlatform] = useState({});
    const [availablePlatforms, setAvailablePlatforms] = useState(availablePlatformsUtils);
    const [selectedPlatform, setSelectedPlatform] = useState(availablePlatforms[0]);
    const [generatingLicense, setGeneratingLicense] = useState(false);
    const [settingPlatform, setSettingPlatform] = useState(false);

    const navigate = useNavigate();

    const displayMandatoryValue = (name) => {
        if (name === "platform-selector") {
            const element = document.getElementById(name);
            if (settingPlatform)
                element.getElementsByTagName("select")[0].style.border = "2px solid red";
            else
                element.getElementsByTagName("button")[0].style.border = "2px solid red";
        } else {
            const element = document.getElementById(name);
            element.style.border = "2px solid red";
        }
    }

    const displayErrorMessage = (message) => {
        const element = document.getElementById("error-message");
        element.innerHTML = "License could not be created.<br>Reason:<br>" + message;
    }

    const checkField = (name, value) => {
        const element = document.getElementById(name);

        switch (name) {
            case "customer-input":
                if (value === "") element.style.border = "2px solid red";
                else element.style.border = "";
                break;

            case "platform-selector":
                if (value === true) element.getElementsByTagName("select")[0].style.border = "";
                break;
            default:
                break;
        }
    }

    const checkAsterisks = (production) => {
        for (const platform of Object.keys(license.Platforms)) {
            appDomains[platform].forEach((domain) => {
                const element = document.getElementById(platform + domain.id);

                if (domain.text === "")
                    element.style.border = "2px solid red";
                else {
                    const errorMsg = document.getElementById(platform + domain.id + "-error");

                    // Asterisk (*) only allowed on non-production
                    if (production && domain.text === "*") {
                        element.style.border = "2px solid red";
                        errorMsg.innerHTML = "Asterisk cannot be used on production licenses. This domain will be ignored."
                    } else {
                        element.style.border = "";
                        errorMsg.innerHTML = ""
                    }
                }
            });
        }
    }

    const generateLicense = async (license) => {
        const formattedAppDomains = [];

        for (const platform of Object.keys(license.Platforms)) {
            if (platform === "html5") {
                const platformDomains = platformToAppDomain(retrieveDomains(appDomains[platform]), platform);
                formattedAppDomains.push(...platformDomains);
            } else {
                formattedAppDomains.push(...platformToAppDomain(appDomains[platform], platform));
            }
        }
        
        const newLicense = {
            ...license
        };

        newLicense.Timelock = new Date(license.Timelock).getTime();
    
        console.log('License', newLicense);

        try {
            setGeneratingLicense(true);
            
            if (!await checkUser(setAuthenticated))
                return;

            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
    
            const key = await fetch(getBaseURL() + '/addlicense', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Lic-Mag': token
                },
                body: JSON.stringify({
                    Details: newLicense,
                    AppDomains: formattedAppDomains
                })
            });
            
            const resp = await key.json();

            if (key.status === 200 && !resp.errorMessage) {
                setGeneratingLicense(false);
                setForceDashboardUpdate(true);
    
                navigate(`/license/${resp.key}`);
            } else {
                setGeneratingLicense(false);
                console.error('Error generating license', resp.errorMessage);
                displayErrorMessage(resp.errorMessage + "<br>Please try again and, if the problem persists, contact with support.");
            }
        } catch (e) {
            console.error('License error', e);
            displayErrorMessage("Unknown error:<br>" + e + "<br>Please contact with support if the problem persists.");
        }
    }
        
    const createLicense = async (license) => {
        let message = "";

        if (!license.Customer || license.Customer === "") {
            displayMandatoryValue("customer-input");
            message += "Customer field cannot be empty.<br>";
        }

        if (!license.Timelock) {
            displayMandatoryValue("timelock-input");
            message += "Timelock must be set.<br>";
        }

        if (Object.keys(platforms).length === 0) {
            displayMandatoryValue("platform-selector");
            message += "At least one platform must be selected<br>";
        }

        if (!license.Customer || license.Customer === "" || !license.Timelock || Object.keys(platforms).length === 0) {
            displayErrorMessage(message);
            return;
        }
        
        await generateLicense(license);
    }

    const checkTimelock = (event) => {
        if (new Date(event.target.value).getTime() >= new Date()) {
            setLicense({...license, Timelock: event.target.value});
        } else {
            const newCurrentTime = timestampToDate(new Date());

            setLicense({...license, Timelock: newCurrentTime})
            event.target.value = newCurrentTime;
        }
    }

    const updateDomains = useCallback((platform, domains) => {
        // Check if all domains have text
        domains.forEach(domain => {
            const element = document.getElementById(platform + domain.id);

            if (domain.text === "")
                element.style.border = "2px solid red";
            else if (license.ProductionLicense && domain.text === "*") { // Asterisk (*) only allowed on non-production
                element.style.border = "2px solid red";
                const errorMsg = document.getElementById(platform + domain.id + "-error");
                errorMsg.innerHTML = "Asterisk cannot be used on production licenses. This domain will be ignored."
            } else {
                element.style.border = "";
                const errorMsg = document.getElementById(platform + domain.id + "-error");
                errorMsg.innerHTML = ""
            }
        });

        appDomains[platform] = domains;
        setAppDomains(appDomains);
    }, [appDomains, license.ProductionLicense]);

    const addPlatform = (event) => {
        platforms[selectedPlatform] = {};

        const localPlatforms = [...license.LocalPlatforms];

        if (!localPlatforms.includes(selectedPlatform))
            localPlatforms.push(selectedPlatform);

        if (webPlatforms.includes(selectedPlatform)) {
            if (!localPlatforms.includes("html5"))
                localPlatforms.push("html5");
        }

        if (editorPlatforms.includes(selectedPlatform)) {
            if (!localPlatforms.includes("windows"))
                localPlatforms.push("windows");       

            if (!localPlatforms.includes("macos"))
                localPlatforms.push("macos");            
        }

        setPlatform(platforms);

        setLicense({
            ...license,
            Platforms: platforms,
            LocalPlatforms: localPlatforms
        });

        const newAvailablePlatforms = availablePlatforms.filter(platform => {
            if (platform === selectedPlatform)
                return false;

            return true;
        });

        setAvailablePlatforms(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);
        setSettingPlatform(false);
    }

    const removePlatform = (removedPlatform) => {
        delete platforms[removedPlatform];
        setPlatform(platforms);

        const newAvailablePlatforms = availablePlatformsUtils.filter(
            platform => availablePlatforms.includes(platform) || platform === removedPlatform);
    
        setLicense({
            ...license,
            Platforms: platforms
        });

        setAvailablePlatforms(newAvailablePlatforms);
        setSelectedPlatform(newAvailablePlatforms[0]);

        const element = document.getElementById("platform-selector").getElementsByTagName("select")[0];

        if (element)
            element.value = 0;
    }
    
    return(
        <div id="new-license">
            <div id="title">Create new license</div>
            
            <Customer checkField={checkField} name={license.Customer} setLicense={(value) => {setLicense({...license, Customer: value})}}/>

            <div id="timelock" className="input-container">
                <label data-tooltip-id="my-tooltip"
                data-tooltip-content="Date until which the license will work">Timelock</label>
                <input id="timelock-input" type="datetime-local" name='timelock' min={license.Timelock} defaultValue={license.Timelock} onBlur={checkTimelock}></input>
            </div>

            <div id="local-platforms-container" className="input-container">
                <label data-tooltip-id="my-tooltip"
            data-tooltip-content="Platforms where the playback will be enabled using local domains/apps (editors included)">Local Platforms</label>
                <LocalPlatforms platforms={license.LocalPlatforms} updatePlatforms={(localPlatforms) => setLicense({...license, LocalPlatforms: localPlatforms})}/>
            </div>

            <div id="apps-container">
                <div className="apps-title" data-tooltip-id="my-tooltip"
                data-tooltip-content="Domains are used on HTML5, PS4, and PS5. Any other platforms use the appId of the app."
                >Apps</div>
                {
                    Object.keys(platforms).map(platform => {
                        return <Platform platform={platform} key={platform} details={platforms[platform]} sendDomain={updateDomains} removePlatform={removePlatform}/>
                    })
                }
                {
                    availablePlatforms.length > 0 ?
                    <div id="platform-selector">
                        {
                            settingPlatform ?
                                <Fragment>
                                    <select onChange={e => setSelectedPlatform(e.target.value)}>
                                        {
                                            availablePlatforms.map(platform => {
                                                return (
                                                    <Fragment key={platform}>
                                                        <option value={platform}>{platform}</option>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </select>
                                    <button onClick={e => {checkField("apps-container", true); addPlatform();}}>Add</button>
                                    <button onClick={() => setSettingPlatform(false)}>Cancel</button>
                                </Fragment>
                            :
                                <button onClick={() => setSettingPlatform(true)}>Add platform</button>
                        }
                        </div>
                    : (null)
                    }
            </div>

            {
                !credentials.type &&
                <div id="webgl" className="checkbox-container">
                    <input type="checkbox" onChange={e => setLicense({...license, Type: e.target.checked ? 'WebGL' : 'Basic'})} checked={license.Type === 'WebGL' ? true : false}/>
                    WebGL
                </div>
            }

            <div id="production" className="checkbox-container">
                <input type="checkbox" onChange={e => {checkAsterisks(e.target.checked); setLicense({...license, ProductionLicense: e.target.checked})}} />
                Production License
            </div>

            <div id="watermark" className="checkbox-container">
                <input type="checkbox" onChange={e => setLicense({...license, Watermark: e.target.checked})} />
                <div data-tooltip-id="my-tooltip"
                data-tooltip-content="If enabled, watermark will be displayed on all the apps.">Watermark</div>
            </div>

            <div id="is-active" className="checkbox-container">
                <input type="checkbox" onChange={e => setLicense({...license, IsActive: e.target.checked})} checked={license.IsActive}/>
                <div data-tooltip-id="my-tooltip"
                data-tooltip-content="Disabling the license will prevent its usage on all platforms, local domains included">Active</div>
            </div>

            <div id="create-license">
                <button className="create-license-button" onClick={(e) => createLicense(license, navigate)}>{generatingLicense ? 'Generating license...' : 'Create license'}</button>
                <div id="error-message"></div>
            </div>
            <Tooltip id="my-tooltip" className="my-tooltip" multiline={true} />
        </div>
    );
}