import "./LicenseDetails.css";

import React from "react";

export default function LicenseDetails({licenseDetails}) {
    return <div id="license-details">
        <div id="details-title">Changes</div>
        
        {
            licenseDetails.CreationDetails ?
            <div>
                Created by {licenseDetails.CreationDetails.User} on {new Date(licenseDetails.CreationDetails.Date).toGMTString()}
            </div>
            :
            <div>
                No creation date found
            </div>
        }

        {
            licenseDetails.LastUpdate ?
            <div>
                Last update by {licenseDetails.LastUpdate.User} on {new Date(licenseDetails.LastUpdate.Date).toGMTString()}
            </div>
            :
            <div>
                No updates have been made
            </div>
        }
    </div>
}